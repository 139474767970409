import React, { Component } from "react";
import DefaultLayout from "../../container/DefaultLayout";
import { DashboardActions } from "../../actions";
import { START_DATE, END_DATE } from "react-dates/constants";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Image,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Chart } from "react-google-charts";
import moment from "moment";
import { Input, Col, Label } from "reactstrap";
import {
  DateRangePicker,
  isInclusivelyBeforeDay,
  isInclusivelyAfterDay,
} from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../assets/css/codebase.css";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalNumberOfCardsScanned: 0,
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),
      startDate1: moment().startOf("month"),
      endDate1: moment().endOf("month"),
      radioValue: 1,
      radioValue1: 1,
      graphTitleValue: "This Week",
      graphTitleValue1: "This Week",
      essentialCount: 0,
      professionalCount: 0,
      enterpriseCount: 0,
      totalUsers: 0,
      loading: false,
      cardModal: false,
      userOwnCard: "",
      loadingModal1: true,
      registeredVendors:0,
      registeredUsers:0,
      totalBooking:0,
      pendingBooking:0,
      subscribedUsers:0,
      // slotAvailable:"00:00 - 00:00",
      bookingReviewed:0,
      completedBookings:0,
      vendors:[],
      users:[],
      modal:false,
      modalRewards:false,
      startTime:"00:00",
      endTime:"00:00"
    };
  }
  componentDidMount() {
    this.initialFunction();
  }

  initialFunction() {
    DashboardActions.counter({}, (err, res) => {
      if (!err) {
        this.setState({
          registeredVendors:res.data.vendorCount,
          registeredUsers:res.data.userCount,
          totalBooking:res.data.totalBooking,
          pendingBooking:res.data.pendingBooking,
          subscribedUsers:0,
          completedBookings:res.data.completedBookings,
          bookingReviewed:res.data.reviewedBooking,
          startTime: res.data.slotsAvailable ?  res.data.slotsAvailable.startTime : "00:00",
          endTime: res.data.slotsAvailable ?  res.data.slotsAvailable.endTime : "00:00",
          addition: res.data.rewardValues ?  res.data.rewardValues.addition : "00",
          deduction: res.data.rewardValues ?  res.data.rewardValues.deduction : "00",
        });
      }
    });
    DashboardActions.usersThisWeek({from_date: moment().startOf("isoweek").toString(),
    to_date: moment().endOf("isoweek").toString(),}, (err, res) => {
      if (!err) {
        this.setState({
          users: res.data,
        });
      }
    });
    DashboardActions.vendorThisWeek({from_date: moment().startOf("isoweek").toString(),
    to_date: moment().endOf("isoweek").toString(),}, (err, res) => {
      if (!err) {
        this.setState({
          vendors: res.data,
        });
      }
    });
  }

  editSlot = () =>{
    this.setState({modal:true})
  }
  editRewards = () =>{
    this.setState({modalRewards:true})
  }
  handleChange = (event) => {
    let target = event.target,
      name = target.name;
    console.log(event.target);
    this.setState(
      {
        [event.target.name]: event.target.value,
      }
    );
  }
  updateTimeSlot = () =>{
    DashboardActions.timeSlotUpdate({start:this.state.startTime,end:this.state.endTime}, (err, res) => {
      if (!err) {
        if(res.success == true){
          toast(res.message);
          this.setState({
            modal:false          
        });
        }else{
          toast(res.message);
        }
       
      }
    });
  }
  updateReviewValue = () =>{
    DashboardActions.reviewValueUpdate({addition:this.state.addition,deduction:this.state.deduction}, (err, res) => {
      if (!err) {
        if(res.success == true){
          toast(res.message);
          this.setState({
            modalRewards:false          
        });
        }else{
          toast(res.message);
        }
       
      }
    });
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );
  render() {
    return (
      <div
        id="page-container"
        className="sidebar-o enable-page-overlay side-scroll page-header-modern main-content-boxed"
      >
        <DefaultLayout></DefaultLayout>
        <ToastContainer> </ToastContainer>
        {this.state.loading === true ? (
          this.loading()
        ) : (
          <React.Fragment>
            {/* First row */} <div class="row gutters-tiny pb-15">
              <div class="col-lg pr-15">
                <a class="block block-link-pop text-center">
                  <div class="block-content">
                    <p class="font-size-h1 ">
                      <strong>{this.state.registeredVendors}</strong>
                    </p>
                    <p class="font-w600">
                      <i class="si si-frame text-muted mr-5"></i>
                      Vendor's Registered
                    </p>
                  </div>
                </a>
              </div>
              <div class="col-lg pr-15">
                <a class="block block-link-pop text-center">
                  <div class="block-content">
                    <p class="font-size-h1 ">
                      <strong>{this.state.registeredUsers}</strong>
                    </p>
                    <p class="font-w600">
                      <i class="si si-cloud-download text-muted mr-5"></i>
                      User's Registered
                    </p>
                  </div>
                </a>
              </div>
              <div class="col-lg pr-15">
                <a class="block block-link-pop text-center">
                  <div class="block-content">
                    <p class="font-size-h1 ">
                      <strong>{this.state.totalBooking}</strong>
                    </p>
                    <p class="font-w600">
                      <i class="si si-badge text-muted mr-5"></i>
                      Total Booking's
                    </p>
                  </div>
                </a>
              </div>
              <div class="col-lg pr-15">
                <a class="block block-link-pop text-center">
                  <div class="block-content">
                    <p class="font-size-h1 ">
                      <strong>{this.state.pendingBooking}</strong>
                    </p>
                    <p class="font-w600">
                      <i class="si si-users text-muted mr-5"></i>
                      Pending Booking's
                    </p>
                  </div>
                </a>
              </div>
            </div>
            {/* second row */}
            <div class="row gutters-tiny pb-15">
            <div class="col-lg pr-15">
                
                <a class="block block-link-pop text-center">
                <div className="timerDiv">
                  <div class="block-content">
                  <div className="edit-btn"> 
                  <button type="button" class="btn btn-sm btn-secondary" onClick={this.editRewards}>
                    <i class="fa fa-edit mr-5"></i>
                </button>
                  </div>
                    <p class="font-size-h1 addition">
                      <strong>{this.state.addition}</strong>
                    </p>
                    <p class="font-size-h1 deduction ">{this.state.deduction}</p>
                    <p class="font-w600">
                      <i class="si si-cloud-download text-muted mr-5"></i>
                      Reward Points
                    </p>
                  </div>  
                </div>
                </a>
              </div>

              <div class="col-lg pr-15">
                
                <a class="block block-link-pop text-center">
                <div className="timerDiv">
                  <div class="block-content">
                  <div className="edit-btn"> 
                  <button type="button" class="btn btn-sm btn-secondary" onClick={this.editSlot}>
                    <i class="fa fa-edit mr-5"></i>
                </button>
                  </div>
                    <p class="font-size-h1 ">
                      <strong>{this.state.startTime}-{this.state.endTime}</strong>
                    </p>
                    <p class="font-w600">
                      <i class="si si-cloud-download text-muted mr-5"></i>
                      Slot Available
                    </p>
                  </div>
                </div>
                </a>
              </div>
              <div class="col-lg pr-15">
                <a class="block block-link-pop text-center">
                  <div class="block-content">
                    <p class="font-size-h1 ">
                      <strong>{this.state.bookingReviewed}</strong>
                    </p>
                    <p class="font-w600">
                      <i class="si si-badge text-muted mr-5"></i> 
                      Booking Reviewed
                    </p>
                  </div>
                </a>
              </div>
              <div class="col-lg pr-15">
                <a class="block block-link-pop text-center">
                  <div class="block-content">
                    <p class="font-size-h1 ">
                      <strong>{this.state.completedBookings}</strong>
                    </p>
                    <p class="font-w600">
                      <i class="si si-users text-muted mr-5"></i>Completed Bookings
                    </p>
                  </div>
                </a>
              </div>
            </div>
           
            {/* end */}

            <div class="row pt-10">
              <div class="col-md-6 ">
                <div class="block">
                  <div class="block-header block-header-default">
                    <h3 class="block-title">New Users This Week</h3>
                  </div>
                  <div class="block-content">
                    <table class="table table-vcenter">
                      <thead>
                        <tr>
                          <th>Phone</th>
                          <th class="text-center">Platform</th>
                          <th class="text-center">Created At</th>
                          {/* <th class="text-center">Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.users.length < 1 ? (
                          <tr>No data Found</tr>
                        ) : (
                          this.state.users.map((e) => {
                            return (
                              <tr>
                                <td>{e.countryCode + "-" + e.phoneNumber}</td>
                                <td class="text-center">
                                  {e.deviceDetails[0] &&
                                  e.deviceDetails[0].deviceType === 2 ? (
                                    <i
                                      class="fa fa-android text-success mr-5"
                                      style={{ fontSize: "25px" }}
                                    ></i>
                                  ) : e.deviceDetails[0] &&
                                    e.deviceDetails[0].deviceType === 1 ? (
                                    <i
                                      class="fa fa-apple text-disabled mr-5"
                                      style={{ fontSize: "25px" }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td class="text-center">
                                  {moment(e.created_at).format("DD/MM/YYYY")}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="block">
                  <div class="block-header block-header-default">
                  <h3 class="block-title">New Vendors This Week</h3>
                  </div>
                  <div class="block-content">
                    <table class="table table-vcenter">
                      <thead>
                      <tr>
                          <th>Phone</th>
                          <th class="text-center">Platform</th>
                          <th class="text-center">Created At</th>
                          {/* <th class="text-center">Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.vendors.length < 1 ? (
                          <tr>No data Found</tr>
                        ) : (
                          this.state.vendors.map((e) => {
                            return (
                              <tr>
                                <td>{e.countryCode + "-" + e.phoneNumber}</td>
                                <td class="text-center">
                                  {e.deviceDetails[0] &&
                                  e.deviceDetails[0].deviceType === 2 ? (
                                    <i
                                      class="fa fa-android text-success mr-5"
                                      style={{ fontSize: "25px" }}
                                    ></i>
                                  ) : e.deviceDetails[0] &&
                                    e.deviceDetails[0].deviceType === 1 ? (
                                    <i
                                      class="fa fa-apple text-disabled mr-5"
                                      style={{ fontSize: "25px" }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td class="text-center">
                                  {moment(e.created_at).format("DD/MM/YYYY")}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isOpen={this.state.modal}
              className="p-0"
            >
              <ModalHeader className="pb-2" toggle={() => this.setState({modal:false})}>
               Update Time Slot
              </ModalHeader>
                  <ModalBody>
                    {this.state.userOwnCard === undefined ? (
                      <h4>no data found</h4>
                    ) : (
                      <div>
                        <div className="row" style={{ paddingTop: 23 }}>
                          <div className="col-md-6">
                            <label>Start Time:</label>
                          </div>
                          <div className="col-md-6">
                          <label>End Time:</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                              <input type="time" 
                              className="form-control"
                              name="startTime"
                              id="startTime"
                              value={this.state.startTime}
                              onChange={this.handleChange}
                              />     
                           </div>
                           <div className="col-md-2"></div>
                          <div className="col-md-4">
                          <input type="time" 
                              className="form-control"
                              name="endTime"
                              id="endTime"
                              value={this.state.endTime}
                              onChange={this.handleChange}
                              />                               </div>
                        </div>
                      </div>
                    )}
                  </ModalBody>
                  <ModalFooter className="pt-30">
                          <Button
                            color="primary"
                            onClick={this.updateTimeSlot}
                          >
                            Proceed
                          </Button>
                          {/* <Button
                            color="secondary"
                            onClick={() => this.setState({ modal: false ,modalWork:""})}
                          >
                            Cancel
                          </Button> */}
                        </ModalFooter>
            </Modal>
            <Modal
              isOpen={this.state.modalRewards}
              className="p-0"
            >
              <ModalHeader className="pb-2" toggle={() => this.setState({modalRewards:false})}>
               Update Rewards Value
              </ModalHeader>
                  <ModalBody>
                    {this.state.userOwnCard === undefined ? (
                      <h4>no data found</h4>
                    ) : (
                      <div>
                        <div className="row" style={{ paddingTop: 23 }}>
                          <div className="col-md-6">
                            <label>Addition(On Success)</label>
                          </div>
                          <div className="col-md-6">
                          <label>Deduction(On Cancel)</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                              <input type="text" 
                              className="form-control"
                              name="addition"
                              id="addition"
                              value={this.state.addition}
                              onChange={this.handleChange}
                              />     
                           </div>
                           <div className="col-md-2"></div>
                          <div className="col-md-4">
                          <input type="text" 
                              className="form-control"
                              name="deduction"
                              id="deduction"
                              value={this.state.deduction}
                              onChange={this.handleChange}
                              />                               </div>
                        </div>
                      </div>
                    )}
                  </ModalBody>
                  <ModalFooter className="pt-30">
                          <Button
                            color="primary"
                            onClick={this.updateReviewValue}
                          >
                            Proceed
                          </Button>
                          {/* <Button
                            color="secondary"
                            onClick={() => this.setState({ modal: false ,modalWork:""})}
                          >
                            Cancel
                          </Button> */}
                        </ModalFooter>
            </Modal>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Dashboard;
