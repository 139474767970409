import React, { Component } from "react";
import { UserListingActions ,VendorListingActions} from "../../actions";
import ReactPaginate from "react-paginate";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import DefaultLayout from "../../container/DefaultLayout";
// import "../AddSubCategories/node_modules/toasted-notes/src/styles.css";
import Switch from "react-switch";
import appConstants from "../../config/AppConstants";
import moment from "moment";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
 
class VendorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      query: "",
      pageNo: 1,
      userCount: 0,
      userlimit: 10,
      pageCount: 0,
      loading: true,
      userNameForToggle: "",
      toggleValue: false,
      loadingModal: true,
      userIdForToggle: "",
      planToBeGivenByAdmin: "",
      premiumPlanSelectedForUserListing: "",
      noOfDaysForPremiumMembershipGrant: 1,
      planCategory: "",
      modalDisplayValue: "",
      isFormValid: false,
      defaultImage: `/assets/img/user-not-found.png`,
    };
    this.fetchNextList = this.fetchNextList.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let {
      pageNo,
      query,
      userlimit,
      premiumPlanSelectedForUserListing,
      planCategory,
    } = this.state;
    VendorListingActions.fetchVendor(
      {
        // value: query,
        pageNo,
        limit: userlimit,
        // premiumPlanSelectedForUserListing: premiumPlanSelectedForUserListing,
        // planCategory: planCategory,
      },
      (err, res) => {
        if (!err) {
          this.setState({
            // planToBeGivenByAdmin: "",
            pageCount: Math.ceil(res.count / this.state.userlimit),
            vendors: res.data,
            loading: false,
          });
        }
      }
    );
  }
  fetchNextList(pageNumber) {
    let page = pageNumber.selected + 1;
    this.setState(
      {
        pageNo: page,
      },
      () => {
        this.fetchData();
      }
    );
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  viewCard(id) {
    this.props.history.push(`/VendorsList/VendorProfile?id=${id}`);
  }
  
  changeHandler = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        pageNo: 1,
      },
      () => {
        this.fetchData();
      }
    );
  };
  makePremium = () => {
    UserListingActions.makePremium(
      {
        id: this.state.userIdForToggle,
        premiumCategory: this.state.planToBeGivenByAdmin,
        planExpiryDate: moment()
          .add(this.state.noOfDaysForPremiumMembershipGrant, "days")
          .toString(),
      },
      (err, res) => {
        if (!err) {
          console.log(res);
          this.setState({
            modal: !this.state.modal,
            loading: false,
            isFormValid: false,
            noOfDaysForPremiumMembershipGrant: 1,
          });
          this.fetchData();
        }
      }
    );
  };
  handleChange(event) {
    let target = event.target,
      value = target.type === "checkbox" ? target.checked : target.value,
      name = target.name;
    if (event.target.value !== "Premium") {
      this.setState({ premiumPlanSelectedForUserListing: "" });
    }
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        if (this.state.planToBeGivenByAdmin === "") {
          this.fetchData();
          this.setState({ isFormValid: false });
        } else {
          this.setState({ isFormValid: true });
        }
      }
    );
  }

  toggle = (name, id, value) => {
    this.setState({
      userIdForToggle: id,
      modalDisplayValue: value,
      modal: !this.state.modal,
      userNameForToggle: name,
      loadingModal: false,
    });
  };
  render() {
    const { vendors, loading, pageCount,defaultImage } = this.state;
    let premiumCategory = appConstants.premiumCategory;
    let planCategory = appConstants.planCategory;
    console.log(this.state.noOfDaysForPremiumMembershipGrant);
    return (
      <div
        id="page-container"
        className="sidebar-o enable-page-overlay side-scroll page-header-modern"
      >
        <DefaultLayout></DefaultLayout>
        <main id="main-container">
          <div className="content" style={{ paddingTop: 0 }}>
            <div class="top-search2">
            <div className="row mb-30 mt-30">
              <div className="col-3">
                <h2>Hazlo Vendors</h2>
              </div>
                <React.Fragment>
                  {" "}
                  <div class="col-9">
                    <ul
                      className="breadbrumb-ul-input"
                      style={{
                        listStyle: "none",
                        paddingLeft: "0px",
                        marginBottom: "0px",
                        position: "absolute",
                        right: 0,
                      }}
                    >
                      {/* <li
                        style={{ display: "inline-Block", marginLeft: "10px" }}
                      >
                        <div className="">
                          <select
                            name="planCategory"
                            id="planCategory"
                            value={this.state.planCategory}
                            onChange={this.handleChange}
                            class="form-control custom-select"
                          >
                            <option value="">All Category</option>
                            {planCategory.map((e) => {
                              return (
                                <option value={e.value} key={e.value}>
                                  {e.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </li> */}
                      <li
                        style={{ display: "inline-Block", marginLeft: "10px" }}
                      >
                        <div class="">
                          <div class="d-flex justify-content-end">
                            <div class=" mr-15">
                              <ul class="filter-ul ">
                                <li class="w-300px pl-4">
                                  <form>
                                    <div class="input-group">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search"
                                        id="text"
                                        name="query"
                                        value={this.state.query}
                                        onChange={this.changeHandler}
                                      />
                                      <div class="input-group-append">
                                        <button
                                          type="submit"
                                          disabled
                                          class="btn btn-secondary"
                                        >
                                          <i class="fa fa-search"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </li>
                              </ul>
                            </div>
                            {/* <div>
                              <button
                                class="btn btn-md btn-primary float-right text-nowrap"
                                onClick={this.downloadCsv}
                              >
                                Download CSV
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </React.Fragment>

              {/* start */}

              {/* end */}
              </div>
            </div>
            {loading ? (
              this.loading()
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="block">
                    {/* <div className="block-header block-header-default">
                    <div className="row w-100">
                      <div className="col-lg-12">
                        <div className=""></div>
                      </div>
                    </div>
                  </div> */}
                    <div className="block-content block-content-full pt-30">
                     <div class="custom-table t2">
                      <div class="table-responsive">
                        <table className="table table-striped table-borderless table-vcenter dataTable no-footer">
                          <thead
                            style={{
                              verticalAlign: "baseline",
                              whiteSpace: "nowrap",
                            }}
                            className=""
                          >
                            <tr className="">
                            <th className="text-black">
                                <b>Business Name</b>
                              </th>
                              <th className="text-black">
                                <b>Phone Number</b>
                              </th>
                              <th className="text-black">
                                <b>Email</b>
                              </th>
                              <th className="text-black">
                                <b>name</b>
                              </th>
                              <th className="text-black">
                                <b>PLATFORM</b>
                              </th> 
                              <th className="text-black">
                                <b>Display Picture</b>
                              </th>
                              <th className="text-black">
                                <b>Date Joined</b>
                              </th>
                              {/* <th className="text-black">
                                <b>PUSH NOTIFICATION</b>
                              </th> */}
                              {/* <th className="text-black">
                                <b>CATEGORY</b>
                              </th> */}
                              <th className="text-black ">
                                <b>ACTION</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {vendors.map((user) => {
                              return (
                                <tr className="">
                                    <td className="font-w600">
                                    {user ? user.businessName : null}
                                  </td>
                                  <td className="font-w600">
                                    {/* {user && user.firstName
                                      ? user.firstName + user.lastName
                                      : ""} */}
                                    {user ? `${user.countryCode}-${user.phoneNumber}` : null}
                                  </td>
                                  <td className="font-w600">
                                    {user && user.email ? user.email : "-"}
                                  </td>
                                  <td className="font-w600">
                                    {user && user.name ? user.name : "-"}
                                  </td>
                                    {user && user.deviceDetails
                                      ? user.deviceDetails[0].deviceType === 1 ? (
                                        <td className="d-none d-sm-table-cell">
                                          IOS
                                        </td>
                                      ) : (
                                        <td className="d-none d-sm-table-cell">
                                          ANDROID
                                        </td>
                                      )
                                      : ""}
                                     <td className="d-none d-sm-table-cell td-img">
                                     <img
                                      src={user.displayPicture!== ""? user.displayPicture : defaultImage  }
                                      border={3}
                                      height={80}
                                      width={80}
                                    />
                                    </td>
                                {/*  <td className="d-none d-sm-table-cell">
                                      <div
                                        style={{
                                          display: "flex",
                                        }}
                                      >
                                        <Switch
                                          checked={user.pushNotification}
                                          onColor="#3f9ce8"
                                          checkedIcon={false}
                                          height={20}
                                          width={40}
                                          disabled={true}
                                          // onChange={() => {
                                          //   let remove = "remove";
                                          //   this.toggle(
                                          //     user.firstName,
                                          //     user._id,
                                          //     remove
                                          //   );
                                          // }}
                                        />
                                      </div>
                                      </td>*/}
                                  <td className="d-none d-sm-table-cell">
                                  {user && user.created_at
                                      ?moment(user.created_at).format("DD,MMMM,YYYY")
                                      : ""}
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => {
                                        this.viewCard(user._id);
                                      }}
                                      className=" btn btn-primary  fa fa-eye"
                                    ></button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      </div>
                      <br/>
                      <br/>
                      <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={this.state.userCount}
                        pageRangeDisplayed={this.state.userlimit}
                        onPageChange={this.fetchNextList}
                        containerClassName={"pagination-student"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.pageNo - 1}
                      />
                      <Modal isOpen={this.state.modal} className="p-0">
                        <ModalHeader className="pb-2">
                          Confirmation Page
                        </ModalHeader>

                        {this.state.loadingModal ? (
                          <div className="animated fadeIn pt-1 text-center">
                            Loading...
                          </div>
                        ) : (
                          <ModalBody>
                            Are you sure you want to{" "}
                            {this.state.modalDisplayValue}{" "}
                            <b>{this.state.userNameForToggle}</b> as premium
                            user?
                            {this.state.modalDisplayValue === "remove" ? (
                              ""
                            ) : (
                              <div>
                                <div className="row" style={{ paddingTop: 23 }}>
                                  <div className="col-md-4">
                                    <label>Select Plan:</label>
                                  </div>
                                  <div className="col-md-5">
                                    {" "}
                                    <select
                                      name="planToBeGivenByAdmin"
                                      id="planCategory"
                                      value={this.state.planToBeGivenByAdmin}
                                      onChange={this.handleChange}
                                      className="form-control custom-select"
                                    >
                                      <option value="" disabled selected>
                                        Select Category
                                      </option>
                                      {premiumCategory.map((e) => {
                                        return (
                                          <option value={e.value} key={e.value}>
                                            {e.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>

                                <br />
                                <div className="row">
                                  <div className="col-md-4">
                                    <label>Number Of Days:</label>
                                  </div>
                                  <div className="col-md-5">
                                    <input
                                      className="form-control"
                                      type="number"
                                      min="1"
                                      value={
                                        this.state
                                          .noOfDaysForPremiumMembershipGrant
                                      }
                                      name="noOfDaysForPremiumMembershipGrant"
                                      onChange={this.handleChange}
                                      id="noOfDaysForPremiumMembershipGrant"
                                      placeholder="No. Of Days..."
                                      disabled={!this.state.isFormValid}
                                    ></input>
                                  </div>
                                </div>
                              </div>
                            )}
                          </ModalBody>
                        )}
                        <ModalFooter>
                          <Button
                            color="primary"
                            disabled={
                              this.state.modalDisplayValue === "remove"
                                ? false
                                : !this.state.isFormValid
                            }
                            onClick={this.makePremium}
                          >
                            Proceed
                          </Button>
                          <Button
                            color="secondary"
                            onClick={() => this.toggle(0)}
                          >
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    );
  }
}

export default VendorList;
