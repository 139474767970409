import React, { Component } from "react";
import { CategoryListingActions,VideosActions } from "../../actions";
import ReactPaginate from "react-paginate";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import DefaultLayout from "../../container/DefaultLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
// import "../AddSubCategories/node_modules/toasted-notes/src/styles.css";
import appConstants from "../../config/AppConstants";
import superagent from "superagent";
import Switch from "react-switch";
import imageCompression from 'browser-image-compression';
import config from "../../config/Config";
const cookie = new Cookies();

const BACKEND_URL = config.BACKEND_URL;
let token = cookie.get("token", { path: "/" });
class addCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      allCategories: [],
      allSubCategories:[],
      allServices:[],
      loading: true,
      modal: false,
      update: false,
      categoryName: "",
      categoryId: "",
      query: "",
      img:"",
      subCategoryName:"",
      serviceName:"",
      description:"",
      price:"",
      multiple:false,
      category:""
    };
    this.fetchData = this.fetchData.bind(this);
    this.onUploadFile = this.onUploadFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let { query } = this.state;
    VideosActions.fetchVideos({}, (err, res) => {
      if (!err) {
        this.setState({
            allServices: res.data,
          loading: false,
        });
      }
    });
    // CategoryListingActions.fetchServices({ search: query }, (err, res) => {
    //   if (!err) {
    //     this.setState({
    //         allServices: res.data,
    //       loading: false,
    //     });
    //   }
    // });
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  changeHandler = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.fetchData();
      }
    );
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  deleteVideo = (id) => {
    VideosActions.deleteVideo(
        {
          id: id,
        },
        (err, res) => {
          if (!err) {
            console.log(res);
            this.setState({
            //   modal: !this.state.modal,
              // loading: false,
            //   update: false,
            });
            toast.success(res.message);
            this.fetchData();
          }
        }
      )
  }
  addCategory = () => {
    {
        this.state.update === true
        ? VideosActions.updateVideo(
            {
                description: this.state.description,
                name: this.state.serviceName,
                id: this.state.categoryId,
            },
            (err, res) => {
              if (!err) {
                console.log(res);
                this.setState({
                  modal: !this.state.modal,
                  // loading: false,
                  update: false,
                });
                toast.success(res.message)
                this.fetchData();
              }
            }
          )
        :  superagent
        .post(`${BACKEND_URL}/admin/addVideo`)
        .set("x-access-token", token)
        .accept("application/json")
        .field("title", this.state.serviceName)
        .field("description", this.state.description)
        .attach("video", this.state.img, this.state.img.name)
        .then((result) => {
          console.log("result", result.body.success);
          if (result.body.success === true) {
            this.setState({
              modal: !this.state.modal,
              // loading: false,
              // isFormValid: false,
            });
            toast("Added successfully");
            this.fetchData();
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  async onUploadFile(event) {
    const imageFile = event.target.files[0];
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    // const options = {
    //   maxSizeMB: 0.5,
    //   maxWidthOrHeight: 960,
    //   useWebWorker: true
    // }
    // try {
    //   const compressedFile = await imageCompression(imageFile, options);
    //   console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
    //   console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      this.setState({
            img: imageFile
          });
      // await uploadToServer(compressedFile); // write your own logic
    // } catch (error) {
    //   console.log(error);
    // }

  }
  toggleMulti = (id,make) => {
    console.log("id",id)
    CategoryListingActions.multi(
      {       
       id,
      },
      (err, res) => {
        if (!err) {
          console.log(res);
          this.fetchData();
        }
      }
    );
  };
  featureVideo= (id,make) => {
    console.log("id",id)
    VideosActions.feature(
      {       
       id,
      },
      (err, res) => {
        if (!err) {
          if(res.success === true)
            {this.fetchData();
            toast.success(res.message)}
            else{
              toast.error(res.message)
            }
        }
      }
    );
  };

  render() {
    const { allServices,allSubCategories, loading, query ,allCategories} = this.state;
    console.log("query", query);
    let premiumCategory = appConstants.premiumCategory;
    let planCategory = appConstants.planCategory;
    console.log(this.state.noOfDaysForPremiumMembershipGrant);
    return (
      <div
        id="page-container"
        className="sidebar-o enable-page-overlay side-scroll page-header-modern"
      >
        <DefaultLayout></DefaultLayout>
        <ToastContainer> </ToastContainer>

        <main id="main-container">
          <div className="content" style={{ paddingTop: 0 }}>
            <div className="row mb-30 mt-30">
              <div className="col-3">
                <h2>Featured Videos</h2>
              </div>

              <React.Fragment>
                {" "}
                <div class="col-9">
                  <ul
                    className="breadbrumb-ul-input"
                    style={{
                      listStyle: "none",
                      paddingLeft: "0px",
                      marginBottom: "0px",
                      position: "absolute",
                      right: 0,
                    }}
                  >
                       <li class="w-300px pl-4">
                                {" "}
                              
                              </li>
                    <li style={{ display: "inline-Block", marginLeft: "10px" }}>
                      <div class="">
                        <div class="d-flex justify-content-end">
                         <div class="text-right mr-15">
                           {/*   <ul class="filter-ul ">
                              <li class="w-300px pl-4">
                              <select
                                  name="planCategory"
                                  id="planCategory"
                                  value={this.state.planCategory}
                                  onChange={this.changeHandler}
                                  class="form-control custom-select"
                                >
                                  <option value="">All Category</option>
                                  {allCategories.map((e) => {
                                    return (
                                      <option value={e._id} key={e._id}>
                                        {e.subCategoryName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </li>
                            </ul>*/}
                          </div> 
                          <div>
                            <button
                              class="btn btn-md btn-primary float-right text-nowrap"
                              onClick={() => this.setState({ modal: true })}
                            >
                              Add Video
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            </div>
            {loading ? (
              this.loading()
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="block">
                    <div className="block-content block-content-full pt-30">
                      <div class="table-responsive">
                        <table className="table table-striped table-borderless table-vcenter dataTable no-footer">
                          <thead
                            style={{
                              verticalAlign: "baseline",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <tr>
                              <th className="text-black">
                                <b>Title</b>
                              </th>
                              <th className="text-black">
                                <b>Description</b>
                              </th>
                              <th className="text-black">
                                <b>Thumbnail</b>
                              </th>
                              <th className="text-black">
                                <b>URL</b>
                              </th>
                              <th className="text-black">
                                <b>Featured</b>
                              </th>
                              <th className="text-right text-black">
                                <b>Actions</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {allServices.map((category) => {
                              return (
                                <tr>
                                  <td className="font-w600">
                                    {category && category.title
                                      ? category.title
                                      : ""}
                                  </td>
                                  <td className="font-w600">
                                      {category && category.description
                                      ? category.description
                                      : ""}
                                  
                                  </td>
                                  <td className="font-w600">
                                     <img src={category && category.thumbnail
                                      ? category.thumbnail
                                      : ""}
                                      border={3}
                                      height={90}
                                      width={90} />
                                  </td>
                                  <td className="font-w600">
                                      <a href={category && category.video
                                      ? category.video
                                      : ""}  target="_blank" >Video</a>
                                    {/* {category && category.price
                                      ? category.price
                                      : ""} */}
                                  </td>
                                  <td>
                                  {category && category.featured === true?
                                   <Switch
                                        checked={category && category.featured?  category.featured:false}
                                        height={20}
                                        width={40}
                                        uncheckedIcon={false}
                                        onChange={() => {
                                          let make = "Un-hidden";
                                          this.featureVideo(
                                            category._id,
                                            make
                                          );
                                        }}
                                      /> :
                                   <Switch
                                   checked={category && category.featured?  category.featured:false}
                                   height={20}
                                      width={40}
                                      uncheckedIcon={false}
                                      onChange={() => {
                                        let make = "hidden";
                                        this.featureVideo(
                                          category._id,
                                          make
                                        );
                                      }}
                                    /> }</td>
                                  <td className="text-right">
                                    <div class="btn-group">
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-secondary js-tooltip-enabled"
                                        data-toggle="tooltip"
                                        title="Edit"
                                        onClick={() => {
                                          this.setState({
                                            modal: !this.state.modal,
                                            serviceName: category.title,
                                            description:category.description,
                                            update: true,
                                            categoryId: category._id,
                                          });
                                        }}
                                        data-original-title="Edit"
                                      >
                                        <i class="fa fa-pencil"></i>
                                      </button>
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-secondary js-tooltip-enabled"
                                        data-toggle="tooltip"
                                        title="Delete"
                                        onClick={() => this.deleteVideo(category._id)}
                                        data-original-title="Delete"
                                      >
                                        <i class="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>{" "}
                      <br />
                      <Modal isOpen={this.state.modal} className="p-0">
                        {this.state.update === true ? (
                          <ModalHeader className="pb-2">
                            Edit Video
                          </ModalHeader>
                        ) : (
                          <ModalHeader className="pb-2">
                            Add Video
                          </ModalHeader>
                        )}

                        {this.state.loadingModal ? (
                          <div className="animated fadeIn pt-1 text-center">
                            Loading...
                          </div>
                        ) : (
                          <ModalBody>
                            {this.state.modalDisplayValue === "remove" ? (
                              ""
                            ) : (
                              <div>
                                <div className="row" style={{ paddingTop: 23 }}>
                                  <div className="col-md-4">
                                    <label>Title:</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      name="serviceName"
                                      class="form-control"
                                      value={this.state.serviceName}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <br />
                                <div className="row" style={{ paddingTop: 23 }}>
                                  <div className="col-md-4">
                                    <label>Description:</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      name="description"
                                      class="form-control"
                                      value={this.state.description}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <br />
                              <div className="row">
                                <div className="col-md-3">
                                  <label>Upload Video:</label>
                                </div>
                                <div className="col-md-9">
                                  <input
                                    id="file"
                                    type="file"
                                    accept="video/mp4,video/x-m4v,video/*"
                                    name="badge"
                                    className="inputButton"
                                    onChange={this.onUploadFile}
                                    disabled={this.state.update === true}
                                  />
                                </div>
                              </div>
                              </div>
                            )}
                          </ModalBody>
                        )}
                        <ModalFooter>
                          <Button
                            color="primary"
                            // disabled={
                            //   this.state.modalDisplayValue === "remove"
                            //     ? false
                            //     : !this.state.isFormValid
                            // }
                            onClick={this.addCategory}
                          >
                            Proceed
                          </Button>
                          <Button
                            color="secondary"
                            onClick={() =>
                              this.setState({
                                modal: false,
                                categoryName: "",
                                update: false,
                              })
                            }
                          >
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    );
  }
}

export default addCategory;
