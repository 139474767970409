import Agent from "../services/RequestInstance";
import { ServerError } from "../utils/helpers";

import config from "../config/Config";

const BACKEND_URL = config.BACKEND_URL;

function fetchPromo(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/fetchPromo`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function addPromo(payload, cb) {
    Agent.fire("post", `${BACKEND_URL}/admin/addPromo`)
      .send(payload)
      .end((err, res) => {
        var error =
          err || res.error
            ? ServerError(res)
            : res.body && res.body.error
            ? ServerError(res)
            : null;
        if (typeof cb === "function") return cb(error, res && res.body);
      });
  }
  function deleteVideo(payload, cb) {
    Agent.fire("get", `${BACKEND_URL}/admin/deleteVideo`)
      .query(payload)
      .end((err, res) => {
        var error =
          err || res.error
            ? ServerError(res)
            : res.body && res.body.error
            ? ServerError(res)
            : null;
        if (typeof cb === "function") return cb(error, res && res.body);
      });
  }
  function updateVideo(payload, cb) {
    Agent.fire("post", `${BACKEND_URL}/admin/updateVideo`)
      .send(payload)
      .end((err, res) => {
        var error =
          err || res.error
            ? ServerError(res)
            : res.body && res.body.error
            ? ServerError(res)
            : null;
        if (typeof cb === "function") return cb(error, res && res.body);
      });
  }
export default {
    fetchPromo,
    addPromo,
    deleteVideo,
    updateVideo
};
