import Agent from "../services/RequestInstance";
import { ServerError } from "../utils/helpers";

import config from "../config/Config";

const BACKEND_URL = config.BACKEND_URL;

function fetchVendor(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/vendorsList`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function feedbacks(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/feedbacks`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

function addExchange(payload, cb) {
  window.location = `${BACKEND_URL}/admin/downloadCsv`;
}

function userList(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/usersList`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function specificUserNotification(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/sendPushNotificationToSpecificUser`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function allVendorsNotification(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/sendPushNotificationAllVendor`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function makePremium(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/makeUserPremium`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function fetchProfile(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/fetchProfile`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
export default {
  fetchVendor,
  feedbacks,
  addExchange,
  makePremium,
  userList,
  specificUserNotification,
  allVendorsNotification,
  fetchProfile
};