import React, { Component } from "react";
import { CategoryListingActions } from "../../actions";
import ReactPaginate from "react-paginate";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import DefaultLayout from "../../container/DefaultLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "../AddSubCategories/node_modules/toasted-notes/src/styles.css";
import appConstants from "../../config/AppConstants";
import superagent from "superagent";

import imageCompression from 'browser-image-compression';
import config from "../../config/Config";

const BACKEND_URL = config.BACKEND_URL;

class addCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      allCategories: [],
      allSubCategories:[],
      loading: true,
      modal: false,
      update: false,
      categoryName: "",
      categoryId: "",
      query: "",
      img:"",
      subCategoryName:"",
      description:"",
      price:"",
      category:""
    };
    this.fetchData = this.fetchData.bind(this);
    this.onUploadFile = this.onUploadFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let { query } = this.state;
    CategoryListingActions.fetchCategory({}, (err, res) => {
      if (!err) {
        this.setState({
          allCategories: res.data,
          loading: false,
        });
      }
    });
    CategoryListingActions.fetchSubCategory({ search: query }, (err, res) => {
      if (!err) {
        this.setState({
          allSubCategories: res.data,
          loading: false,
        });
      }
    });
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  changeHandler = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.fetchData();
      }
    );
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  addCategory = () => {
    {
      this.state.update === true
        ? CategoryListingActions.updateCategory(
            {
              categoryName: this.state.categoryName,
              categoryId: this.state.categoryId,
            },
            (err, res) => {
              if (!err) {
                console.log(res);
                this.setState({
                  modal: !this.state.modal,
                  // loading: false,
                  update: false,
                });
                this.fetchData();
              }
            }
          )
        :  superagent
        .post(`${BACKEND_URL}/admin/addSubCategory`)
        .set("Authorization", "...")
        .accept("application/json")
        .field("name", this.state.subCategoryName)
        // .field("price", this.state.price)
        // .field("description", this.state.description)
        .field("category", this.state.category)
        .attach("image", this.state.img, this.state.img.name)
        .then((result) => {
          console.log("result", result.body.success);
          if (result.body.success === true) {
            this.setState({
              modal: !this.state.modal,
              // loading: false,
              // isFormValid: false,
            });
            toast("Added successfully");
            this.fetchData();
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  async onUploadFile(event) {
    const imageFile = event.target.files[0];
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 960,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      this.setState({
            img: compressedFile
          });
      // await uploadToServer(compressedFile); // write your own logic
    } catch (error) {
      console.log(error);
    }

  }

  render() {
    const { allSubCategories, loading, query ,allCategories} = this.state;
    console.log("query", query);
    let premiumCategory = appConstants.premiumCategory;
    let planCategory = appConstants.planCategory;
    console.log(this.state.noOfDaysForPremiumMembershipGrant);
    return (
      <div
        id="page-container"
        className="sidebar-o enable-page-overlay side-scroll page-header-modern"
      >
        <DefaultLayout></DefaultLayout>
        <main id="main-container">
          <div className="content" style={{ paddingTop: 0 }}>
            <div className="row mb-30 mt-30">
              <div className="col-3">
                <h2>Sub-Category</h2>
              </div>

              <React.Fragment>
                {" "}
                <div class="col-9">
                  <ul
                    className="breadbrumb-ul-input"
                    style={{
                      listStyle: "none",
                      paddingLeft: "0px",
                      marginBottom: "0px",
                      position: "absolute",
                      right: 0,
                    }}
                  >
                       <li class="w-300px pl-4">
                                {" "}
                              
                              </li>
                    <li style={{ display: "inline-Block", marginLeft: "10px" }}>
                      <div class="">
                        <div class="d-flex justify-content-end">
                          <div class="text-right mr-15">
                            <ul class="filter-ul ">
                              <li class="w-300px pl-4">
                              <select
                                  name="query"
                                  id="query"
                                  value={this.state.query}
                                  onChange={this.changeHandler}
                                  class="form-control custom-select"
                                >
                                  <option value="">All Category</option>
                                  {allCategories.map((e) => {
                                    return (
                                      <option value={e._id} key={e._id}>
                                        {e.categoryName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </li>
                            </ul>
                          </div>
                          <div>
                            <button
                              class="btn btn-md btn-primary float-right text-nowrap"
                              onClick={() => this.setState({ modal: true })}
                            >
                              Add Sub-Category
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            </div>
            {loading ? (
              this.loading()
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="block">
                    <div className="block-content block-content-full pt-30">
                      <div class="table-responsive">
                        <table className="table table-striped table-borderless table-vcenter dataTable no-footer">
                          <thead
                            style={{
                              verticalAlign: "baseline",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <tr>
                              <th className="text-black">
                                <b>Name</b>
                              </th>
                              <th className="text-black">
                                <b>Image</b>
                              </th>
                              {/* <th className="text-black">
                                <b>Description</b>
                              </th>
                              <th className="text-black">
                                <b>Price</b>
                              </th> */}
                               <th className="text-black">
                                <b>Category</b>
                              </th>
                              <th className="text-right text-black">
                                <b>Actions</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {allSubCategories.map((category) => {
                              return (
                                <tr>
                                  <td className="font-w600">
                                    {category && category.subCategoryName
                                      ? category.subCategoryName
                                      : ""}
                                  </td>
                                  <td className="font-w600">
                                    <img src={category && category.subCategoryImage
                                      ? category.subCategoryImage
                                      : ""} 
                                      border={3}
                                      height={60}
                                      width={60}/>
                                  </td>
                                  {/* <td className="font-w600">
                                    {category && category.description
                                      ? category.description
                                      : ""}
                                  </td>
                                  <td className="font-w600">
                                    {category && category.price
                                      ? category.price
                                      : ""}
                                  </td> */}
                                  <td className="font-w600">
                                    {category && category.category &&  category.category.categoryName
                                      ?  category.category.categoryName
                                      : ""}
                                  </td>
                                  <td className="text-right">
                                    <div class="btn-group">
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-secondary js-tooltip-enabled"
                                        data-toggle="tooltip"
                                        title="Edit"
                                        onClick={() => {
                                          this.setState({
                                            modal: !this.state.modal,
                                            categoryName: category.name,
                                            update: true,
                                            categoryId: category._id,
                                          });
                                        }}
                                        data-original-title="Edit"
                                      >
                                        <i class="fa fa-pencil"></i>
                                      </button>
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-secondary js-tooltip-enabled"
                                        data-toggle="tooltip"
                                        title="Delete"
                                        onClick={() => this.deleteQuote(category._id)}
                                        data-original-title="Delete"
                                      >           
                                        <i class="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>{" "}
                      <br />
                      <Modal isOpen={this.state.modal} className="p-0">
                        {this.state.update === true ? (
                          <ModalHeader className="pb-2">
                            Edit Category
                          </ModalHeader>
                        ) : (
                          <ModalHeader className="pb-2">
                            Add Sub-Category
                          </ModalHeader>
                        )}

                        {this.state.loadingModal ? (
                          <div className="animated fadeIn pt-1 text-center">
                            Loading...
                          </div>
                        ) : (
                          <ModalBody>
                            {this.state.modalDisplayValue === "remove" ? (
                              ""
                            ) : (
                              <div>
                                <div className="row" style={{ paddingTop: 23 }}>
                                  <div className="col-md-4">
                                    <label>Name:</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      name="subCategoryName"
                                      class="form-control"
                                      value={this.state.subCategoryName}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <br />
                                {/* <div className="row" style={{ paddingTop: 23 }}>
                                  <div className="col-md-4">
                                    <label>Description:</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      name="description"
                                      class="form-control"
                                      value={this.state.description}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <br />
                                <div className="row" style={{ paddingTop: 23 }}>
                                  <div className="col-md-4">
                                    <label>Price:</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="number"
                                      name="price"
                                      value={this.state.price}
                                      class="form-control"
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <br /> */}
                                <div className="row" style={{ paddingTop: 23 }}>
                                  <div className="col-md-4">
                                    <label>Category:</label>
                                  </div>
                                  <div className="col-md-8">
                                  <select
                                  name="category"
                                  id="category"
                                  value={this.state.planCategory}
                                  onChange={this.changeHandler}
                                  class="form-control custom-select"
                                >
                                  <option value="">All Category</option>
                                  {allCategories.map((e) => {
                                    return (
                                      <option value={e._id} key={e._id}>
                                        {e.categoryName}
                                      </option>
                                    );
                                  })}
                                </select>
                                  </div>
                                </div>
                                <br />
                              <div className="row">
                                <div className="col-md-3">
                                  <label>Upload image:</label>
                                </div>
                                <div className="col-md-9">
                                  <input
                                    id="file"
                                    type="file"
                                    accept="image/*"
                                    name="badge"
                                    className="inputButton"
                                    onChange={this.onUploadFile}
                                  />
                                </div>
                              </div>
                              </div>
                            )}
                          </ModalBody>
                        )}
                        <ModalFooter>
                          <Button
                            color="primary"
                            // disabled={
                            //   this.state.modalDisplayValue === "remove"
                            //     ? false
                            //     : !this.state.isFormValid
                            // }
                            onClick={this.addCategory}
                          >
                            Proceed
                          </Button>
                          <Button
                            color="secondary"
                            onClick={() =>
                              this.setState({
                                modal: false,
                                categoryName: "",
                                update: false,
                              })
                            }
                          >
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    );
  }
}

export default addCategory;
