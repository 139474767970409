import React, { Component } from "react";
import Select from "react-select";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  Button,
  ModalFooter,
} from "reactstrap";
import { ViewCardsActions } from "../../actions";
import { UserListingActions } from "../../actions";
import appConstants from "../../config/AppConstants";
import moment from "moment";

import DefaultLayout from "../../container/DefaultLayout";
import ReactPaginate from "react-paginate";
import Image from "react-bootstrap/Image";
import Switch from "react-switch";

class ViewCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      Id: "",
      plan: "",
      planByAdmin: "",
      userCards: [],
      userOwnCard: [],
      category: [],
      selectedCardCategoryId: [],
      modal: false,
      modalPremium: false,
      cardsCount: 0,
      isPremium: "",
      isPremiumByAdmin: "",
      noOfDaysForPremiumMembershipGrant: 1,
      isFormValid: false,
      pageNo: 1,
      userlimit: 10,
      pageCount: 0,
      userCount: 0,
    };
    this.initialFunction = this.initialFunction.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchNextList = this.fetchNextList.bind(this);
  }

  async componentDidMount() {
    this.initialFunction();
  }
  initialFunction(selectedId) {
    let payload = {};
    const pathName = this.props.location.pathname.split("/");
    const id = pathName[2];
    if (selectedId) {
      payload.category = selectedId;
    }
    payload.pageNo = this.state.pageNo;
    ViewCardsActions.all(payload, id, (err, res) => {
      if (!err) {
        let name = {};
        let newList = "";
        res.category.map((e) => {
          name = { label: e.name, value: e._id };
          let list = [...newList, name];
          newList = list;
        });
        this.setState({
          pageCount: Math.ceil(res.data.count / this.state.userlimit),
          category: newList,
          cardsCount: res.data.count,
          userCards: res.data.userCardData,
          userOwnCard: res.data.ownCardData,
          planToBeGivenByAdmin: "",
        });
      }
    });
    this.setState({ Id: pathName[2] });
    ViewCardsActions.getId({ id: id }, (err, res) => {
      if (!err) {
        this.setState({
          isPremium: res.data[0].isPremium,
          isPremiumByAdmin: res.data[0].isPremiumByAdmin,
          plan: res.data[0].plan,
          planByAdmin: res.data[0].planByAdmin,
        });
      }
    });
  }

  changeHandler(e) {
    if (e) {
      let id = e.value;
      // e.map((e) => {
      //   id = [...id, e.value];
      // });
      this.setState({ selectedCardCategoryId: id }, () => {
        this.initialFunction(id);
      });
    } else {
      this.initialFunction();
    }
  }
  toggle = (dp) => {
    this.setState({
      modal: !this.state.modal,
      cardDp: dp,
      loadingModal: false,
    });
  };
  togglePremium = (value) => {
    this.setState({
      // userIdForToggle: id,
      modalDisplayValue: value,
      modalPremium: !this.state.modalPremium,
      // userNameForToggle: name,
      loadingModalPremium: false,
    });
  };
  makePremium = () => {
    UserListingActions.makePremium(
      {
        id: this.state.Id,
        premiumCategory: this.state.planToBeGivenByAdmin,
        planExpiryDate: moment()
          .add(this.state.noOfDaysForPremiumMembershipGrant, "days")
          .toString(),
      },
      (err, res) => {
        if (!err) {
          console.log(res);
          this.setState({
            modalPremium: !this.state.modalPremium,
            loadingModalPremium: false,
          });
          this.initialFunction();
        }
      }
    );
  };
  handleChange(event) {
    let target = event.target,
      value = target.type === "checkbox" ? target.checked : target.value,
      name = target.name;
    if (event.target.value !== "Premium") {
      this.setState({ premiumPlanSelectedForUserListing: "" });
    }
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        if (this.state.planToBeGivenByAdmin === "") {
          this.fetchData();
          this.setState({ isFormValid: false });
        } else {
          this.setState({ isFormValid: true });
        }
      }
    );
  }
  fetchNextList(pageNumber) {
    let page = pageNumber.selected + 1;
    this.setState(
      {
        pageNo: page,
      },
      () => {
        this.initialFunction(this.state.selectedCardCategoryId);
      }
    );
  }
  render() {
    const { Id, userCards, userOwnCard } = this.state;
    let premiumCategory = appConstants.premiumCategory;
    console.log(this.state.category);
    console.log("selected id", this.state.selectedCardCategoryId);

    return (
      <div
        id="page-container"
        className="sidebar-o enable-page-overlay side-scroll page-header-modern"
      >
        <DefaultLayout></DefaultLayout>
        <Row></Row>

        {userOwnCard.map((e) => {
          return (
            <div class="col">
              <span class="block text-center">
                <div
                  class="block-content block-content-full"
                  style={{
                    backgroundImage:
                      "url('https://demo.pixelcave.com/codebase/assets/media/photos/photo13.jpg')",
                  }}
                >
                  <div class="block-content text-center block-content-full ">
                    <span class="font-w600 text-white">{e.name}</span>
                  </div>
                  {this.state.isPremium === true ? (
                    <span class="badge badge-warning">PREMIUM</span>
                  ) : this.state.isPremiumByAdmin === true ? (
                    <label class="css-control css-control-sm css-control-warning css-switch">
                      <Switch
                        checked={this.state.isPremiumByAdmin}
                        onColor="#3f9ce8"
                        checkedIcon={false}
                        height={20}
                        width={40}
                        onChange={() => {
                          let remove = "remove";
                          this.togglePremium(remove);
                        }}
                      />
                    </label>
                  ) : (
                    <label class="css-control css-control-sm css-control-warning css-switch">
                      <Switch
                        checked={this.state.toggleValue}
                        height={20}
                        width={40}
                        uncheckedIcon={false}
                        onChange={() => {
                          let make = "make";

                          this.togglePremium(make);
                        }}
                      />
                    </label>
                  )}
                </div>
                <div class="block-content bg-primary-lighter">
                  <div class="row items-push text-center">
                    <div class="col-2">
                      <div class="mb-5">
                        <i class="si si-notebook fa-2x fa-2x text-primary"></i>
                      </div>
                      <div class="font-size-sm text-muted">
                        {this.state.cardsCount} Cards
                      </div>
                    </div>

                    <div class="col-2">
                      <div class="mb-5">
                        <i class="fa fa-industry fa-2x text-primary"></i>
                      </div>
                      <div class="font-size-sm text-muted">{e.industry} </div>
                    </div>
                    <div class="col-2">
                      <div class="mb-5">
                        <i class="fa fa-laptop fa-2x text-primary"></i>
                      </div>
                      <div class="font-size-sm text-muted">{e.website} </div>
                    </div>
                    <div class="col-2">
                      <div class="mb-5">
                        <i class="si si-briefcase fa-2x text-primary"></i>
                      </div>
                      <div class="font-size-sm text-muted">
                        {e.companyname}{" "}
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="mb-5">
                        <i class="si si-paper-plane fa-2x text-primary"></i>
                      </div>
                      <div class="font-size-sm text-muted">
                        {this.state.plan
                          ? this.state.plan
                          : this.state.planByAdmin
                          ? this.state.planByAdmin
                          : "Free"}
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="mb-5">
                        <i class="si si-envelope-letter fa-2x text-primary"></i>
                      </div>
                      <div class="font-size-sm text-muted">{e.emailid} </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          );
        })}
        <hr />
        <Row>
          <Col xs="4">
            <h2 align="left">Cards Saved</h2>
          </Col>
          <Col></Col>
          <Col xs="2">
            <Select
              options={this.state.category}
              maxMenuHeight={150}
              // isMulti
              onChange={(e) => this.changeHandler(e)}
            />
          </Col>
        </Row>
        <div className="block">
          <div className="block-content block-content-full pt-30">
            <div class="table-responsive">
              <table className="table table-striped table-borderless table-vcenter dataTable no-footer">
                <thead>
                  <tr>
                    <th className="text-black">
                      <b>NAME</b>
                    </th>
                    <th className="text-black">
                      <b>emailid</b>
                    </th>
                    <th className="text-black">
                      <b>website</b>
                    </th>
                    <th className="text-black">
                      <b>address1</b>
                    </th>
                    <th className="text-black">
                      <b>companyname</b>
                    </th>
                    <th className="text-black ">
                      <b>industry</b>
                    </th>
                    <th className="text-black ">
                      <b>jobtitle</b>
                    </th>
                    <th className="text-black">
                      <b>categoryName</b>
                    </th>
                    <th className="text-black text-right">
                      <b>View Card</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userCards.map((e) => {
                    return (
                      <tr>
                        <td className="font-w600">{e.name} </td>
                        <td className="font-w600">{e.emailid}</td>
                        <td className="font-w600">{e.website}</td>
                        <td className="font-w600">{e.address1}</td>
                        <td className="font-w600">{e.companyname}</td>
                        <td className="font-w600">{e.industry}</td>
                        <td className="font-w600">{e.jobtitle}</td>
                        <td className="font-w600">
                          {e.categoryId &&
                            e.categoryId[0] &&
                            e.categoryId[0].name}
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              this.toggle(e.dp);
                            }}
                            className=" btn btn-primary float-right fa fa-eye"
                          ></button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <br />
            <Modal
              isOpen={this.state.modal}
              // toggle={() => this.toggle(10)}
              className="p-0"
            >
              <ModalHeader
                toggle={() => this.toggle(0)}
                className="pb-2"
              ></ModalHeader>
              {this.state.loadingModal ? (
                <div className="animated fadeIn pt-1 text-center">
                  Loading...
                </div>
              ) : (
                <ModalBody>
                  <Image src={`${this.state.cardDp}`} thumbnail />
                </ModalBody>
              )}
            </Modal>
            <Modal isOpen={this.state.modalPremium}>
              <ModalHeader>Confirmation Page</ModalHeader>

              {this.state.loadingModal ? (
                <div className="animated fadeIn pt-1 text-center">
                  Loading...
                </div>
              ) : (
                <ModalBody>
                  Are you sure you want to {this.state.modalDisplayValue} this
                  user as premium user?
                  {this.state.modalDisplayValue === "remove" ? (
                    ""
                  ) : (
                    <div>
                      {" "}
                      <div className="row" style={{ paddingTop: 23 }}>
                        <div className="col-md-4">
                          <label>Select Plan:</label>
                        </div>
                        <div className="col-md-5">
                          {" "}
                          <select
                            name="planToBeGivenByAdmin"
                            id="planCategory"
                            value={this.state.planToBeGivenByAdmin}
                            onChange={this.handleChange}
                            className="form-control custom-select"
                          >
                            <option value="" disabled selected>
                              All Category
                            </option>
                            {premiumCategory.map((e) => {
                              return (
                                <option value={e.value} key={e.value}>
                                  {e.label}
                                </option>
                              );
                            })}{" "}
                          </select>
                        </div>
                      </div>{" "}
                      <br />
                      <div className="row">
                        <div className="col-md-4">
                          <label>Number Of Days:</label>
                        </div>
                        <div className="col-md-5">
                          {" "}
                          <input
                            type="number"
                            min="1"
                            value={this.state.noOfDaysForPremiumMembershipGrant}
                            name="noOfDaysForPremiumMembershipGrant"
                            onChange={this.handleChange}
                            id="noOfDaysForPremiumMembershipGrant"
                            placeholder="No. Of Days..."
                            className="form-control"
                            disabled={!this.state.isFormValid}
                          ></input>
                        </div>
                      </div>
                    </div>
                  )}
                </ModalBody>
              )}
              <ModalFooter>
                <Button
                  color="primary"
                  disabled={!this.state.isFormValid}
                  onClick={this.makePremium}
                >
                  Proceed
                </Button>
                <Button color="secondary" onClick={() => this.togglePremium(0)}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={this.state.userCount}
              pageRangeDisplayed={this.state.userlimit}
              onPageChange={this.fetchNextList}
              containerClassName={"pagination-student"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={this.state.pageNo - 1}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ViewCard;
