import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
  }
  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  logout() {
    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    window.location.href = `/#/login`;
  }
  render() {
    return (
      <header id="page-header">
        <div class="content-header pt-0 pb-10 mr-0">
          <div class="content-header-section ml-auto">
            <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
              <DropdownToggle
                caret
                tag="button"
                type="button"
                className={"btn"}
              >
                Admin
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={this.logout}>
                  <i class="si si-logout mr-5"></i>Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
