import Agent from "../services/RequestInstance";
import { ServerError } from "../utils/helpers";

import config from "../config/Config";

const BACKEND_URL = config.BACKEND_URL;

function fetchBookings(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/fetchBookings`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function fetchBooking(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/fetchBooking`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function fetchSubCategory(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/allSubCategories`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function fetchServices(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/allServices`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

function addCategory(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/addCategory`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function updateCategory(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/updateCategory`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function deleteCategory(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/deleteCategory`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function multi(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/toggleMulti`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
export default {
    fetchBookings,
    fetchBooking,
  addCategory,
  updateCategory,
  deleteCategory,
  fetchSubCategory,
  fetchServices,
  multi
};
