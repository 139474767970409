import React, { Component } from "react";
import { UserListingActions } from "../../actions";
import DefaultLayout from "../../container/DefaultLayout";
import BreadCrumb from "../BreadCrumb";

import photo39 from "../../assets/media/photos/photo39.jpg";
var qs = require("qs");


class UserListInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:{},
      isFormValid: false,
      defaultImage: `/assets/img/user-not-found.png`,
    };
    this.fetchData = this.fetchData.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let id = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).id;
   
    UserListingActions.fetchUser({ id }, (err, res) => {
      if (!err) {
        this.setState({
          data: res.data,
          // bookingInfo: res.data.booking,
          loading: false,
        });
      }
    });
  }
  
  redirectUserToBookings(id) {
    this.props.history.push(`/UsersList/UserProfile/Bookings?userId=${id}`);
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  render() {
    const { users, loading, pageCount, data } = this.state;
    return (
      <div
        id="page-container"
        className="sidebar-o enable-page-overlay side-scroll page-header-modern"
      >
        <DefaultLayout></DefaultLayout>
        <main id="main-container">
          <div className="content" style={{ paddingTop: 0 }}>
          <BreadCrumb {...this.props}></BreadCrumb>
            {loading ? (
              this.loading()
            ) : (
              <div className=" block block-content block-content-full pt-30">
                <div className="row text-center">
                  <div class="col-md-5">
                    <div className="img-width">
                      <img src={data.userDetails  ? data.userDetails.displayPicture : photo39} />
                    </div>
                    <div class="font-w600 mt-10"></div>
                    <div class="font-size-sm"></div>
                  </div>
                  <div class="col-md-7">
                    <div class="custom-table">
                    <div class="table-responsive ">
                      <table className="table table-striped table-borderless table-vcenter dataTable no-footer">
                        <tbody className="">
                          <tr>
                            <td className="font-weight-bold" >Total Booking Completed</td>
                            <td className="font-w600"> {data.bookingCompleted}</td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold" >Total Booking Cancelled</td>
                            <td className="font-w600"> {data.bookingCancelled}</td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold" >Total Spent</td>
                            <td className="font-w600">{ data.totalSpend  ?  data.totalSpend  : "$0.00" }</td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold" >Bookings</td>
                            <td><button
                        onClick={() => {
                          this.redirectUserToBookings(data.userDetails._id);
                        }}
                        className=" btn btn-primary  fa fa-eye"
                      ></button>{" "}  </td>
                         </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    );
  }
}

export default UserListInfo;
