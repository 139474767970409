import React, { Component } from 'react';
import {Breadcrumbs,Link,Typography} from '@material-ui/core';

export default class breadcCrumb extends Component {
    render() {
        const {history,location:{pathname}}= this.props;
        const pathNames = pathname.split("/").filter(x => x)
        console.log("this.prop breadcrum",this.props);
        return (
            <div className="custom-bread mb-20">

            <div>
                <Breadcrumbs aria-label="breadcrumb">
                {pathNames.map((name,index)=>{
                    const routeTo = `/${pathNames.slice(0,index + 1).join("/")}`
                    const isLast = index === pathNames.lenght - 1;
                    return isLast ? (
                        <Typography color="textPrimary"><h2>{name}</h2></Typography>
                    ):(
                   <Link color="inherit"  ><h2>{name}</h2> 
                </Link>
                    )
                })}
                </Breadcrumbs>
                 {/* <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item active" aria-current="page"><h2>Bookings</h2></li>
                </ol>
              </nav> */}
            </div>
            </div>

        )
    }
}
