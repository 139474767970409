import Dashboard from "../../../components/Dashboard";
import UserList from "../../../components/UserListing";
import sendPushNotification from "../../../components/SendPushNotification";
import viewCard from "../../../components/ViewCard";
// import allcards from "../../../components/AllCards";
import notificationsList from "../../../components/NotificationsList";
import addSubCategory from "../../../components/AddSubCategories";
import addCategory from "../../../components/AddCategory"
import booking from "../../../components/Bookings";
import userList from "../../../components/UserListing";
import services from "../../../components/AddServices";
import video from "../../../components/FeatureVideos";
import banner from "../../../components/Banners";
import promo from "../../../components/Promo";
import support from "../../../components/Support";
import dashboard from "../../../components/Dashboard";
import pushNotification from "../../../components/SendPushNotification";
import vendors from "../../../components/VendorsListing";
import viewVendorProfile from "../../../components/ViewVendorProfile";
import ViewBooking from "../../../components/ViewBooking";
import UserListInfo from "../../../components/UserListInfo";





const routes = [
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: "/vendors",
    exact: true,
    name: "vendors",
    component: vendors,
  },
  {
    path: "/VendorsList/VendorProfile",
    exact: true,
    name: "viewVendorProfile",
    component: viewVendorProfile,
  },
  {
    path: "/VendorsList/VendorProfile/Bookings",
    // exact: true,
    name: "ViewBooking",
    component: booking,
  },
  {
    path: "/UsersList/UserProfile/Bookings",
    // exact: true,
    name: "ViewBooking",
    component: booking,
  },
  
  {
    path: "/Bookings/ViewBooking",
    name: "ViewBooking",
    component: ViewBooking,
  },
  {
    path: "/UserList/Profile",
    name: "UserListInfo",
    component: UserListInfo,
  },
  {
    path: "/promo",
    exact: true,
    name: "promo",
    component: promo,
  },
  {
    path: "/pushNotification",
    exact: true,
    name: "pushNotification",
    component: pushNotification,
  },
  {
    path: "/Bookings",
    name: "Booking",
    component: booking,
  },
  {
    path: "/UsersListing",
    name: "userList",
    component: userList,
  },
  {
    path: "/services",
    name: "services",
    component: services,
  },
  {
    path: "/category",
    name: "category",
    component: addCategory,
  },
  {
    path: "/subCategory",
    name: "subCategory",
    component: addSubCategory,
  },
  {
    path: "/video",
    name: "video",
    component: video,
  },
  {
    path: "/banner",
    name: "banner",
    component: banner,
  },
  {
    path: "/support",
    name: "support",
    component: support,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
  },
];

export default routes;
