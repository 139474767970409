import React, { Component } from "react";
import {
  VideosActions,
  PromoActions,
  BookingActions,
} from "../../actions";
import moment from "moment";
import BreadCrumb from "../BreadCrumb";
import DefaultLayout from "../../container/DefaultLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import config from "../../config/Config";

var qs = require("qs");

class ViewBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      allCategories: [],
      allSubCategories: [],
      allServices: [],
      loading: true,
      modal: false,
      update: false,
      categoryName: "",
      categoryId: "",
      query: "",
      img: "",
      subCategoryName: "",
      serviceName: "",
      description: "",
      price: "",
      multiple: false,
      category: "",
      startDate: new Date(),
      expiryDateANdTIme: "",
      couponExpiryDateAndTime: "",
      radioValue: "",
      dropDownUsers: [],
      dropDownId: [],
      id: "",
      vendorProfile: {},
      bookingInfo:{},
      arrivalTime:{},
      defaultImage: `/assets/img/image-not-found.png`,

    };
    this.fetchData = this.fetchData.bind(this);
    // this.onUploadFile = this.onUploadFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }
  componentDidMount() {
    this.fetchData();
    console.log("propss",this.props)
  }

  fetchData() {
    let id = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).id;
    BookingActions.fetchBooking({ id }, (err, res) => {
      if (!err) {
        this.setState({
          arrivalTime: res.data.arrivalTime,
          bookingInfo: res.data.booking,
          loading: false,
        });
      }
    });
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  changeHandler = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.fetchData();
      }
    );
  };

  handleChange(event) {
    if (
      event.target.name == "radioValueCategorySpecified" &&
      event.target.value == "all"
    ) {
      this.setState({ dropDownId: [] });
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  addCategory = () => {
    {
      this.state.update === true
        ? VideosActions.updateVideo(
            {
              description: this.state.description,
              name: this.state.serviceName,
              id: this.state.categoryId,
            },
            (err, res) => {
              if (!err) {
                console.log(res);
                this.setState({
                  modal: !this.state.modal,
                  // loading: false,
                  update: false,
                });
                toast.success(res.message);
                this.fetchData();
              }
            }
          )
        : PromoActions.addPromo(
            {
              name: this.state.name,
              couponCode: this.state.couponCode,
              discount: this.state.discount,
              couponExpiryDateAndTime: this.state.couponExpiryDateAndTime,
              couponType: this.state.radioValue,
              category: this.state.radioValueCategorySpecified,
              categoryId: this.state.dropDownId,
              description: this.state.description,
            },
            (err, res) => {
              if (!err) {
                console.log(res);
                this.setState({
                  modal: !this.state.modal,
                  // loading: false,
                  update: false,
                });
                toast.success(res.message);
                this.fetchData();
              } else {
                toast.error(res.message);
              }
            }
          );
    }
  };

  redirectViewUserProfile = (id) =>{
    this.props.history.push(`/UserList/Profile?id=${id}`);
  }
  redirectViewVendorProfile = (id) =>{
    this.props.history.push(`/VendorsList/VendorProfile?id=${id}`);
  }

  setDate = (date) => {
    this.setState({
      couponExpiryDateAndTime: date,
    });
  };
  handleChangeDropDown = (e) => {
    this.setState({ dropDownId: e });
  };
  render() {
    const {
      arrivalTime,
      vendorProfile,
      bookingInfo,
      loading,
      query,
      allCategories,
      expiryDateANdTIme,
      startDate,
      defaultImage,
    } = this.state;
    return (
      <div
        id="page-container"
        className="sidebar-o enable-page-overlay side-scroll page-header-modern"
      >
        {console.log("...vendorProfile", vendorProfile)}
        <DefaultLayout></DefaultLayout>
        <ToastContainer> </ToastContainer>

        <main id="main-container">
          <div className="content custom-content" style={{ paddingTop: 0 }}>
            {/* <h2>Booking Invoice</h2> */}
            <div className=" mb-20">
            <BreadCrumb {...this.props}></BreadCrumb>
            </div>
            <div class=" block block-content block-content-full pt-30 p-10">
              <div class="row">
                <div class="col-md-5">
                  <div className="img-width text-center">
                    <img className="img img-fluid"
                      src={
                        bookingInfo.image
                          ? bookingInfo.image
                          : defaultImage
                      }
                    />
                  </div>
                  <div class="font-w600 mt-10">{vendorProfile.name}</div>
                  <div class="font-size-sm">{vendorProfile.businessName}</div>
                </div>
                <div class="col-md-7">
                  <div className="custom-table  mr-20">
                  <div class="table-responsive ">
                    <table className="table table-striped table-borderless table-vcenter dataTable no-footer">
                      <tbody className="">
                        <tr className="">
                          <td className="font-weight-bold">Arrival Time</td>
                          <td className="font-w600"> {arrivalTime && arrivalTime.startTime ? new Date(arrivalTime.startTime).toLocaleTimeString('en-US') :"N/A"}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Complete Time</td>
                          <td className="font-w600">{bookingInfo.endDurationSet ?    arrivalTime && arrivalTime.endTime ? new Date(arrivalTime.endTime).toLocaleTimeString('en-US') :"N/A" : "Not Completed Yet"}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Accept Time</td>
                          <td className="font-w600">3 : 30 PM</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Review</td>
                          <td className="font-w600">{bookingInfo.reviewed ? bookingInfo.review : "Not Reviewed Yet"}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">
                            Requested Time
                          </td>
                          <td className="font-w600">{bookingInfo.scheduleTime}- {moment( bookingInfo.scheduledDate).format("DD/MM/YYYY")}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Address</td>
                          <td className="font-w600">{bookingInfo.address ? bookingInfo.flatBuilding + bookingInfo.address:""}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Total Price</td>
                          <td className="font-w600">{bookingInfo.promoCodeApplied ?<> <strike>{ bookingInfo.totalPrice }</strike><p>{bookingInfo.discountedPrice}</p> </>:  bookingInfo.totalPrice }</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Services</td>
                          <td className="font-w600">  
                           <div class="col-md-12 col-xl-12 pl-0 pr-0">
                            <div class="">
                                <div class="row">
                                  {bookingInfo && bookingInfo.service
                                    ? bookingInfo.service.map((content) => {
                                        return (
                                          <div class="col-md pl-0 pr-0">
                                            <div class="block-content block-content-full block-sticky-options pt-30  ">
                                              {/* <img
                                                class="img-avatar img-avatar-thumb"
                                                src={content.serviceid.name}
                                                alt=""
                                              /> */}
                                              <div class="font-w600 mb-5">
                                                <ul className="userlisting">
                                                  <li>
                                                  {content.serviceid.serviceName}
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                            <div class="block-content block-content-full block-content-sm ">
                                              <div class="font-w600 mb-5">
                                                <ul className="userlisting">
                                                  <li>
                                                  {content.serviceid.description}
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })
                                    : ""}
                                </div>
                              </div>
                           </div>
                        </td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Add-On's</td>
                          <td className="font-w600">   
                        <div class="col-md-12 col-xl-12 pl-0 pr-0">
                        <div class="">
                            <div class="row">
                              {bookingInfo && bookingInfo.addOn
                                ? bookingInfo.addOn.map((content) => {
                                    return (
                                      <div class="col-md">
                                        <div class="block-content block-content-full block-sticky-options pt-30  ">
                                        <div class="font-w600 mb-5">
                                            {content.name}
                                          </div>
                                        </div>
                                        <div class="block-content block-content-full block-content-sm ">
                                          <div class="font-w600 mb-5">
                                            {content.description}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                        </div> 
                        </td>
                        </tr>
                         <tr>
                          <td className="font-weight-bold">Add-On's PaymentMode</td>
                          <td className="font-w600">{bookingInfo.addOnsPaymentMode ? bookingInfo.addOnsPaymentMode:  "No Add-On's" }</td>
                          </tr>
                          <tr>
                          <td className="font-weight-bold">User(Booked By)</td>
                          <td className="font-w600">   <button
                                      onClick={() => {
                                     this.redirectViewUserProfile(bookingInfo.userId)
                                      }}
                                      className=" btn btn-primary fa fa-eye"
                                    ></button> </td>
</tr>
<tr>
                          <td className="font-weight-bold">Vendor(Assigned To)</td>
                          <td className="font-w600">   <button
                                      onClick={() => {
                                     this.redirectViewVendorProfile(bookingInfo.vendorId)
                                      }}
                                      className=" btn btn-primary fa fa-eye"
                                    ></button> </td>
</tr>
                      </tbody>
                    </table>

           


                  </div>
                  </div>
                </div>
                <br />
                <br />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default ViewBooking;
