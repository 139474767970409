import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
// import { SessionActions } from "../../actions";
// import appConstants from "../../config/AppConstants";
import logo from "../../assets/logo.png";
import { NavLink } from "react-router-dom";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDashboard: "",
      activeUserList: "",
      activeSendPush: "",
      activeAllCardList: "",
      activeScheduleNotification: "",
    };
    // this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount() { }
  _handleClick(event) {
    var target = event.target.id;
    console.log("tushar", target);

    // this.setState({
    //   target: event.target.id,
    // });
  }
  render() {
    // let {  email, showPassword } = this.state
    const style = {
      // fontWeight: "bold",
      color: "black",
    };
    return (
      <nav id="sidebar">
        <div className="sidebar-content">
          <div
            className="content-header content-header-fullrow px-15"
            // style={{ backgroundColor: "#bd7f80" }}
          >
            <img
              className="main-logo-c"
              style={{
                // height: 50,
                objectFit: "contain",
                marginTop: -19,
                margiLeft: 0,
                marginRight: 0,
                paddingTop:"10px"
              }}
              src={logo}
            ></img>
            <div className="content-header-section sidebar-mini-visible-b"></div>
            <div className="content-header-section text-center align-parent sidebar-mini-hidden"></div>
          </div>
          <div class="content-side content-side-full">
            <ul class="nav-main">
              {/* <li>
                <NavLink
                  to="/addCategory"
                  activeClassName="selected"
                  activeStyle={style}
                >
                  <i class="si si-speech"></i>
                  <span class="sidebar-mini-hide">Categories</span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/dashboard"
                  activeClassName="selected"
                  activeStyle={style}
                >
                  <i class="si si-cup"></i>
                  <span class="sidebar-mini-hide">Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/category"
                  activeClassName="selected"
                  activeStyle={style}
                >
                  <i class="si si-speech"></i>
                  <span class="sidebar-mini-hide">Categories</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/subCategory"
                  activeClassName="selected"
                  activeStyle={style}
                >
                  <i class="si si-speech"></i>
                  <span class="sidebar-mini-hide">Sub-Categories</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  activeClassName="selected"
                  activeStyle={style}
                >
                  <i class="si si-speech"></i>
                  <span class="sidebar-mini-hide">Services</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Bookings"
                  activeClassName="selected"
                  activeStyle={style}
                >
                  <i class="si si-speech"></i>
                  <span class="sidebar-mini-hide">Bookings</span>
                </NavLink>
              </li>
             
              <li>
                <NavLink
                  to="/video"
                  activeClassName="selected"
                  activeStyle={style}
                >
                  <i class="si si-speech"></i>
                  <span class="sidebar-mini-hide">Videos</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/banner"
                  activeClassName="selected"
                  activeStyle={style}
                >
                  <i class="si si-speech"></i>
                  <span class="sidebar-mini-hide">Banners</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/promo"
                  activeClassName="selected"
                  activeStyle={style}
                >
                  <i class="si si-speech"></i>
                  <span class="sidebar-mini-hide">Promo/Offers</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/support"
                  activeClassName="selected"
                  activeStyle={style}
                >
                  <i class="si si-speech"></i>
                  <span class="sidebar-mini-hide">Support</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/pushNotification"
                  activeClassName="selected"
                  activeStyle={style}
                >
                  <i class="si si-speech"></i>
                  <span class="sidebar-mini-hide">Push Notification</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/UsersListing"
                  activeClassName="selected"
                  activeStyle={style}
                >
                  <i class="si si-speech"></i>
                  <span class="sidebar-mini-hide">Users Listing</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/vendors"
                  activeClassName="selected"
                  activeStyle={style}
                >
                  <i class="si si-speech"></i>
                  <span class="sidebar-mini-hide">Vendor Listing</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
