import Agent from "../services/RequestInstance";
import { ServerError } from "../utils/helpers";

import config from "../config/Config";

const BACKEND_URL = config.BACKEND_URL;

function counter(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/counter`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

function usersThisWeek(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/newUsersThisWeek`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function vendorThisWeek(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/newVendorThisWeek`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function timeSlotUpdate(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/timeSlotUpdate`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function cardsScanned(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/totalNumberOfCardsScanned`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function reviewValueUpdate(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/reviewPointsUpdate`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
export default {
  counter,
  usersThisWeek,
  vendorThisWeek,
  cardsScanned,
  timeSlotUpdate,
  reviewValueUpdate
};
