import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { SessionActions } from "../../../actions";
import appConstants from "../../../config/AppConstants";
// import  scrpt from "../../../assets/js/pages/op_auth_signin.min.js";
import { validateEmail } from "../../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Dashboard from "../Dashboard";
import logo from "../../../assets/logo1.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isFormValid: false,
    };
    this.validateForm = this.validateForm.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  validateForm = () => {
    let { email, password, errors } = this.state;

    if (password && validateEmail(email)) {
      this.setState({
        isFormValid: true,
        errors,
      });
    } else {
      this.setState({
        isFormValid: false,
        errors,
      });
    }
  };
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    // let errors = this.state.errors;
    // switch (name) {
    //   case 'email':
    //     errors.email = validateEmail(value) ? "" : "Email is not valid!";
    //     break;
    //   case 'password':
    //     errors.password = value.length > 0 ? "" : "Password is required!";
    //     break;
    //   default:
    //     break;
    // }
    this.setState(
      {
        [event.target.name]: event.target.value,
        //   errors
      },
      () => {
        this.validateForm();
      }
    );
  }

  handleEnter(event) {
    if (event) {
      event.preventDefault();
    }
    if (event.which === 13) {
      this.setState({ isLoading: true }, () => {
        this.submitForm(event);
      });
    } else {
      this.handleChange(event);
    }
  }

  submitForm(event) {
    if (event) {
      event.preventDefault();
    }

    const { email, password, isAdminLogin, isFormValid } = this.state;
    if (!isFormValid) {
      this.validateForm();
      return false;
    }

    this.setState({ isLoading: true, isResendLink: false });

    // const dataToSend = {
    //   email,
    //   password,
    //   // loginType: "2",
    //   //   loginType: isAdminLogin ? "2" : "1",
    //   // deviceType: "3",
    // };
    SessionActions.signIn(
      { username: email, password: password },
      (err, res) => {
        if (err) {
          let message = appConstants.SERVER_ERROR;
          if (err.message) {
            message = err.message;
          }
          toast(res.message);
          let showtoaster = true;
          let dataToSet = {
            // errorMessage: message
          };
          dataToSet.isLoading = false;
          this.setState(dataToSet);
        } else {
          if(res.success === false){
            toast("Failed");
          }else{
            this.setState({ isLoading: false });
            console.log("token", res);
            document.cookie = `token=${res.token}`;
            window.location.href = "/";
          }
        }
      }
    );
  }

  render() {
    let { email, password } = this.state;
    return (
      <div id="page-container" className="main-content-boxed">
                        <ToastContainer> </ToastContainer>

        <main id="main-container">
          <div className="bg-image login-bg">
            <div className="row mx-0 bg-black-op">
              <div className="hero-static col-md-6 col-xl-8 d-md-flex align-items-md-end">
                <div className="p-30" data-toggle="appear">
                  <p className="font-size-h3 font-w600 text-white">
                    Get Inspired and Create.
                  </p>
                  <p className="font-italic text-white-op">
                    Copyright &copy; <span className="js-year-copy"></span>
                  </p>
                </div>
              </div>
              <div
                className="hero-static col-md-6 col-xl-4 d-flex align-items-center bg-white"
                data-toggle="appear"
                data-className="animated fadeInRight"
              >
                <div className="content content-full">
                  <div className="px-30 py-10">
                    <a className="link-effect font-w700">
                      {/* <i className="si si-fire"></i> */}
                      <img src = {logo} 
                      style={{
                        height: 50,
                        objectFit: "contain",
                        marginTop: -19,
                        margiLeft: 0,
                        marginRight: 0,
                      }}/>
                      <span className="font-size-xl text-primary-dark">
                        HAZLO
                      </span>
                      {/* <span className="font-size-xl">dom</span> */}
                    </a>
                    <h1 className="h3 font-w700 mt-30 mb-10">
                      Welcome to Your Dashboard
                    </h1>
                    <h2 className="h5 font-w400 text-muted mb-0">
                      Please sign in
                    </h2>
                  </div>
                  <form className="js-validation-signin px-30" method="post">
                    <div className="form-group row">
                      <div className="col-12">
                        <div className="form-material floating">
                          <input
                            className="form-control"
                            type="email"
                            id="login-username"
                             placeholder="Email"
                            autoComplete="new-email"
                            name="email"
                            value={email}
                            onKeyUp={this.handleEnter}
                            onChange={this.handleChange}
                          />
                          {/* <label for="login-username">Email</label> */}
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-12">
                        <div className="form-material floating">
                          <input
                            type="password"
                            className="form-control"
                            id="login-password"
                            name="password"
                            placeholder="Password"
                            onKeyUp={this.handleEnter}
                            onChange={this.handleChange}
                          />
                          {/* <label for="login-password">Password</label> */}
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group row">
                                        <div className="col-12">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="login-remember-me" name="login-remember-me"/>
                                                <label className="custom-control-label" for="login-remember-me">Remember Me</label>
                                            </div>
                                        </div>
                                    </div> */}
                    <div className="form-group">
                      <button
                        type="submit"
                        onClick={this.submitForm}
                        className="btn btn-sm btn-hero btn-alt-primary"
                      >
                        <i className="si si-login mr-10"></i> Sign In
                      </button>
                      <div className="mt-30">
                        <a className="link-effect text-muted mr-10 mb-5 d-inline-block">
                          <i className="fa fa-plus mr-5"></i> Create Account
                        </a>
                        <a className="link-effect text-muted mr-10 mb-5 d-inline-block">
                          <i className="fa fa-warning mr-5"></i> Forgot Password
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Login;
