import React, { Component } from "react";
import {
  CategoryListingActions,
  VideosActions,
  PromoActions,
} from "../../actions";
import ReactPaginate from "react-paginate";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import DefaultLayout from "../../container/DefaultLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "../AddSubCategories/node_modules/toasted-notes/src/styles.css";
import appConstants from "../../config/AppConstants";
import superagent from "superagent";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import imageCompression from "browser-image-compression";
import config from "../../config/Config";
import Select from "react-select";


const BACKEND_URL = config.BACKEND_URL;

class addCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      allCategories: [],
      allSubCategories: [],
      allServices: [],
      loading: true,
      modal: false,
      update: false,
      categoryName: "",
      categoryId: "",
      query: "",
      img: "",
      subCategoryName: "",
      serviceName: "",
      description: "",
      price: "",
      multiple: false,
      category: "",
      startDate: new Date(),
      expiryDateANdTIme: "",
      couponExpiryDateAndTime: "",
      radioValue: "",
      dropDownUsers:[],
      dropDownId:[]
    };
    this.fetchData = this.fetchData.bind(this);
    // this.onUploadFile = this.onUploadFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let { query } = this.state;
    PromoActions.fetchPromo({}, (err, res) => {
      if (!err) {
        this.setState({
          allServices: res.data,
          loading: false,
        });
      }
    });
    CategoryListingActions.fetchCategory({ }, (err, res) => {
      if (!err) {
        this.setState({
            allCategories: res.data,
          loading: false,
        });
        let listToSend = [];
        let name;
        this.state.allCategories.map((e) => {
          name = { label: e.categoryName, value: e._id };
         (listToSend = [...this.state.dropDownUsers, name]);
          this.setState({ dropDownUsers: listToSend });
        });
      }
    });
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  changeHandler = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.fetchData();
      }
    );
  };

  handleChange(event) {
    if(event.target.name == "radioValueCategorySpecified" && event.target.value == "all"){
      this.setState({dropDownId:[]})
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  addCategory = () => {
    {
      this.state.update === true
        ? VideosActions.updateVideo(
            {
              description: this.state.description,
              name: this.state.serviceName,
              id: this.state.categoryId,
            },
            (err, res) => {
              if (!err) {
                console.log(res);
                this.setState({
                  modal: !this.state.modal,
                  // loading: false,
                  update: false,
                });
                toast.success(res.message);
                this.fetchData();
              }
            }
          )
        :  PromoActions.addPromo(
          {
            name: this.state.name,
            couponCode: this.state.couponCode,
            discount: this.state.discount,
            couponExpiryDateAndTime:this.state.couponExpiryDateAndTime,
            couponType:this.state.radioValue,
            category:this.state.radioValueCategorySpecified,
            categoryId:this.state.dropDownId,
            description:this.state.description
          },
          (err, res) => {
            if (!err) {
              console.log(res);
              this.setState({
                modal: !this.state.modal,
                // loading: false,
                update: false,
              });
              toast.success(res.message);
              this.fetchData();
            }else{
              toast.error(res.message);

            }
          }
        )
    }
  };

  setDate = (date) => {
    this.setState({
      couponExpiryDateAndTime: date,
    });
  };
  handleChangeDropDown = (e) => {
    this.setState({ dropDownId: e });
  }
  render() {
    const {
      allServices,
      allSubCategories,
      loading,
      query,
      allCategories,
      expiryDateANdTIme,
      startDate,
      couponExpiryDateAndTime,
    } = this.state;
    return (
      <div
        id="page-container"
        className="sidebar-o enable-page-overlay side-scroll page-header-modern"
      >
        <DefaultLayout></DefaultLayout>
        <ToastContainer> </ToastContainer>

        <main id="main-container">
          <div className="content" style={{ paddingTop: 0 }}>
            <div className="row mb-30 mt-30">
              <div className="col-3">
                <h2>Promo/Offers</h2>
              </div>

              <React.Fragment>
                {" "}
                <div class="col-9">
                  <ul
                    className="breadbrumb-ul-input"
                    style={{
                      listStyle: "none",
                      paddingLeft: "0px",
                      marginBottom: "0px",
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    <li class="w-300px pl-4"> </li>
                    <li style={{ display: "inline-Block", marginLeft: "10px" }}>
                      <div class="">
                        <div class="d-flex justify-content-end">
                          <div class="text-right mr-15">
                          </div>
                          <div>
                            <button
                              class="btn btn-md btn-primary float-right text-nowrap"
                              onClick={() => this.setState({ modal: true })}
                            >
                              Add Coupon
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            </div>
            {loading ? (
              this.loading()
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="block">
                    <div className="block-content block-content-full pt-30">
                      <div class="table-responsive">
                        <table className="table table-striped table-borderless table-vcenter dataTable no-footer">
                          <thead
                            style={{
                              verticalAlign: "baseline",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <tr>
                              <th className="text-black">
                                <b>name</b>
                              </th>
                              <th className="text-black">
                                <b>description</b>
                              </th>
                              <th className="text-black">
                                <b>couponCode</b>
                              </th>
                              <th className="text-black">
                                <b>discount</b>
                              </th>
                              <th className="text-black">
                                <b>coupon Expiry Date/Time</b>
                              </th>
                              <th className="text-black">
                                <b>couponType</b>
                              </th>
                              <th className="text-right text-black">
                                <b>category</b>
                              </th>
                              <th className="text-right text-black">
                                <b>Action</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {allServices.map((category) => {
                              return (
                                <tr>
                                  <td className="font-w600">
                                    {category && category.name
                                      ? category.name
                                      : ""}
                                  </td>
                                  <td className="font-w600">{category && category.description
                                      ? category.description
                                      : ""}</td>
                                  <td className="font-w600">
                                    {category && category.couponCode
                                      ? category.couponCode
                                      : ""}
                                  </td>
                                  <td className="font-w600">
                                    {category && category.discount
                                      ? category.discount
                                      : ""}
                                  </td>
                                  <td className="font-w600">
                                    {category &&
                                    category.couponExpiryDateAndTime
                                      ? category.couponExpiryDateAndTime
                                      : ""}
                                  </td>
                                  <td className="font-w600">
                                    {category && category.couponType
                                      ? category.couponType
                                      : ""}
                                  </td>
                                  <td className="font-w600">
                                    {category && category.category
                                      ? category.category
                                      : ""}
                                  </td>
                                  <td className="text-right">
                                    <div class="btn-group">
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-secondary js-tooltip-enabled"
                                        data-toggle="tooltip"
                                        title="Edit"
                                        onClick={() => {
                                          this.setState({
                                            modal: !this.state.modal,
                                            serviceName: category.title,
                                            description: category.description,
                                            update: true,
                                            categoryId: category._id,
                                          });
                                        }}
                                        data-original-title="Edit"
                                      >
                                        <i class="fa fa-pencil"></i>
                                      </button>
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-secondary js-tooltip-enabled"
                                        data-toggle="tooltip"
                                        title="Delete"
                                        onClick={() =>
                                          this.deleteVideo(category._id)
                                        }
                                        data-original-title="Delete"
                                      >
                                        <i class="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>{" "}
                      <br />
                      <Modal isOpen={this.state.modal} className="p-0">
                        {this.state.update === true ? (
                          <ModalHeader className="pb-2">Edit Promo</ModalHeader>
                        ) : (
                          <ModalHeader className="pb-2">Add Promo</ModalHeader>
                        )}

                        {this.state.loadingModal ? (
                          <div className="animated fadeIn pt-1 text-center">
                            Loading...
                          </div>
                        ) : (
                          <ModalBody>
                            {this.state.modalDisplayValue === "remove" ? (
                              ""
                            ) : (
                              <div>
                                <div className="row">
                                  <div className="col-md-4">
                                    <label>Name:</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      name="name"
                                      class="form-control"
                                      value={this.state.name}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="col-md-4">
                                    <label>Description:</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      name="description"
                                      class="form-control"
                                      value={this.state.description}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="col-md-4">
                                    <label>Coupon Code:</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      name="couponCode"
                                      class="form-control"
                                      value={this.state.couponCode}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="col-md-4">
                                    <label>Discount:</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      name="discount"
                                      class="form-control"
                                      value={this.state.discount}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <br/>
                                <div className="row">
                                  <div className="col-md-4">
                                    <label>Coupon Type:</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="radio"
                                      name="radioValue"
                                      class="form-control cc-radio-btn"
                                      value="percentageBased"
                                      onChange={this.handleChange}
                                    />
                                    Percentage
                                    <input
                                      type="radio"
                                      name="radioValue"
                                      class="form-control cc-radio-btn"
                                      value="valueBased"
                                      onChange={this.handleChange}
                                    />
                                    Amount
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="col-md-4">
                                    <label>Coupon Expiry:</label>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="coupon-compo">
                                    <DatePicker
                                      className="form-control"
                                      selected={couponExpiryDateAndTime}
                                      onChange={(date) => this.setDate(date)}
                                      showTimeSelect
                                      minDate={startDate}
                                      value={couponExpiryDateAndTime.toString()}
                                      timeFormat="hh:mm"
                                      timeIntervals={30}
                                      timeCaption="Time"
                                      dateFormat="yyyy/MM/dd hh:mm aa"
                                    />
                                    </div>
                                  </div>
                                </div>
                                <br />
                                                              <div className="row">
                                  <div className="col-md-4">
                                    <label>Categories:</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="radio"
                                      name="radioValueCategorySpecified"
                                      class="form-control cc-radio-btn"
                                      value="all"
                                      onChange={this.handleChange}
                                    />
                                    All
                                    <input
                                      type="radio"
                                      name="radioValueCategorySpecified"
                                      class="form-control cc-radio-btn"
                                      value="specified"
                                      onChange={this.handleChange}
                                    />
                                    Specified
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-8">
                                    {this.state.radioValueCategorySpecified ==
                                    "specified" ? (
                                      <>
                                        <Select
                                          className="mt-20"
                                          options={this.state.dropDownUsers}
                                          maxMenuHeight={120}
                                          isMulti
                                          placeholder="Username"
                                          onChange={this.handleChangeDropDown}
                                          name="dropDownId"
                                          onInputChange={this.handleInputChange}
                                          // onMenuScrollToBottom={() =>
                                          //   this.incrementPage()
                                          // }
                                        />
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </ModalBody>
                        )}
                        <ModalFooter>
                          <Button
                            color="primary"
                            // disabled={
                            //   this.state.modalDisplayValue === "remove"
                            //     ? false
                            //     : !this.state.isFormValid
                            // }
                            onClick={this.addCategory}
                          >
                            Proceed
                          </Button>
                          <Button
                            color="secondary"
                            onClick={() =>
                              this.setState({
                                modal: false,
                                categoryName: "",
                                update: false,
                              })
                            }
                          >
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    );
  }
}

export default addCategory;
