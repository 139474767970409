import Agent from "../services/RequestInstance";
import { ServerError } from "../utils/helpers";

import config from "../config/Config";

const BACKEND_URL = config.BACKEND_URL;

function all(payload, id, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/viewCard/${id}`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function getId(id, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/usersList`)
    .query(id)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

export default {
  all,
  getId,
};
