import React, { Component } from "react";
import {
  CategoryListingActions,
  VideosActions,
  PromoActions,
  SupportActions,
} from "../../actions";
import ReactPaginate from "react-paginate";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import DefaultLayout from "../../container/DefaultLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "../AddSubCategories/node_modules/toasted-notes/src/styles.css";
import appConstants from "../../config/AppConstants";
import superagent from "superagent";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import imageCompression from "browser-image-compression";
import config from "../../config/Config";
import Select from "react-select";
import VendorListingAction from "../../actions/VendorListingAction";
import BreadCrumb from "../BreadCrumb";

const BACKEND_URL = config.BACKEND_URL;
var qs = require("qs");

class support extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      allCategories: [],
      allSubCategories: [],
      allServices: [],
      loading: true,
      modal: false,
      update: false,
      categoryName: "",
      categoryId: "",
      query: "",
      img: "",
      subCategoryName: "",
      serviceName: "",
      description: "",
      price: "",
      multiple: false,
      category: "",
      startDate: new Date(),
      expiryDateANdTIme: "",
      couponExpiryDateAndTime: "",
      radioValue: "",
      dropDownUsers: [],
      dropDownId: [],
      id: "",
      vendorProfile: {},
      rewardPoints: 0,
      acceptedBookings: 0,
      cancelledBookings: 0,
      completedBookings: 0,
    };
    this.fetchData = this.fetchData.bind(this);
    // this.onUploadFile = this.onUploadFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let id = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).id;

    VendorListingAction.fetchProfile({ id }, (err, res) => {
      if (!err) {
        this.setState({
          vendorProfile: res.data.vendor,
          loading: false,
          rewardPoints: res.data.rewardPoints,
          acceptedBookings: res.data.acceptedBookings,
          cancelledBookings: res.data.cancelledBookings,
          completedBookings: res.data.completedBookings,
        });
      }
    });
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  changeHandler = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.fetchData();
      }
    );
  };

  handleChange(event) {
    if (
      event.target.name == "radioValueCategorySpecified" &&
      event.target.value == "all"
    ) {
      this.setState({ dropDownId: [] });
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  addCategory = () => {
    {
      this.state.update === true
        ? VideosActions.updateVideo(
            {
              description: this.state.description,
              name: this.state.serviceName,
              id: this.state.categoryId,
            },
            (err, res) => {
              if (!err) {
                console.log(res);
                this.setState({
                  modal: !this.state.modal,
                  // loading: false,
                  update: false,
                });
                toast.success(res.message);
                this.fetchData();
              }
            }
          )
        : PromoActions.addPromo(
            {
              name: this.state.name,
              couponCode: this.state.couponCode,
              discount: this.state.discount,
              couponExpiryDateAndTime: this.state.couponExpiryDateAndTime,
              couponType: this.state.radioValue,
              category: this.state.radioValueCategorySpecified,
              categoryId: this.state.dropDownId,
              description: this.state.description,
            },
            (err, res) => {
              if (!err) {
                console.log(res);
                this.setState({
                  modal: !this.state.modal,
                  // loading: false,
                  update: false,
                });
                toast.success(res.message);
                this.fetchData();
              } else {
                toast.error(res.message);
              }
            }
          );
    }
  };

  redirectVendorToBookings(id) {
    this.props.history.push(`/VendorsList/VendorProfile/Bookings?vendorId=${id}`);
  }

  
  render() {
    const {
      vendorProfile,
      allSubCategories,
      loading,
      query,
      allCategories,
      expiryDateANdTIme,
      startDate,
      couponExpiryDateAndTime,
    } = this.state;
    return (
      <div
        id="page-container"
        className="sidebar-o enable-page-overlay side-scroll page-header-modern"
      >
      
        <DefaultLayout></DefaultLayout>
        <ToastContainer> </ToastContainer>
        <main id="main-container">
          <div className="content custom-content" style={{ paddingTop: 0 }}>
            <div className="row mb-20 mt-30">
              <div className="col-12" >
         
                <BreadCrumb {...this.props}></BreadCrumb>
                
              </div>
            </div>
            <div>
              <React.Fragment>
                {" "}
                <div class="col-9">
                  <ul
                    className="breadbrumb-ul-input"
                    style={{
                      listStyle: "none",
                      paddingLeft: "0px",
                      marginBottom: "0px",
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    <li class="w-300px pl-4"> </li>
                    <li
                      style={{ display: "inline-Block", marginLeft: "10px" }}
                    ></li>
                  </ul>
                </div>
              </React.Fragment>

              <div class="row ">
                <div class="col-md-12 col-xl-12">
                  <div class="block block-themed">
                    <div class="block-content block-content-full  text-center">
                      <div class="row align-items-center">
                        <div class="col-md-4">
                          <div className="block-content block-content-full bg-gd-sea profile-max">
                          <div className="img-width">
                            <img
                              class="img-avatar img-avatar-thumb"
                              src={vendorProfile.displayPicture}
                              alt=""
                              //   height="60"
                            />
                          </div>
                        </div>
                        <div className="profile-name-ven">
                          <div class="font-w600 mt-10">
                            {vendorProfile.name}
                          </div>
                          <div class="font-size-sm">
                            {vendorProfile.businessName}
                          </div>
                          </div>
                        </div>
                        <div class="col-md-8">
                          {" "}
                          <div class="block-content block-content-full profile-bg">
                            <div class="list-group profile-wpr">
                              <div className="profileright">
                              <div className="block-one">
                              <a
                                class=" list-group-item-action "
                                href="javascript:void(0)"
                              >
                                <i class="fa fa-fw fa-envelope-o mr-5"></i>{" "}
                                {vendorProfile.email}
                              </a>
                              </div>
                              <div className="block-two">
                              <a
                                class=" list-group-item-action"
                                href="javascript:void(0)"
                              >
                                <i class="fa fa-fw fa-phone-square mr-5"></i>{" "}
                                {vendorProfile.countryCode}-
                                {vendorProfile.phoneNumber}
                              </a>
                              </div>
                              </div>
                              <div className="profileleft">
                              <div
                                class="list-group-item list-group-item-action "
                                href="javascript:void(0)"
                              >
                                <img
                                  src={
                                    vendorProfile
                                      ? vendorProfile &&
                                        vendorProfile.category &&
                                        vendorProfile.category.categoryImage
                                      : ""
                                  }
                                />{" "}
                              </div>
                              <div className="profileleftName">
                                {vendorProfile.category &&
                                  vendorProfile.category.categoryName}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-xl-12">
                  <div class="block block-themed text-center">
                    <div class="row">
                      {vendorProfile && vendorProfile.subCategory
                        ? vendorProfile.subCategory.map((content) => {
                            return (
                              <div class="col-md">
                                <div class="block-content block-content-full block-sticky-options pt-30  ">
                                  <img
                                    class="img-avatar img-avatar-thumb"
                                    src={content.subCategoryImage}
                                    alt=""
                                  />
                                </div>
                                <div class="block-content block-content-full block-content-sm ">
                                  <div class="font-w600 mb-5">
                                    {content.subCategoryName}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              {/* HEREEE */}
            </div>
            <div class="row gutters-tiny pb-15">
              <div class="col-lg pr-15">
              <a class="block block-link-pop text-right bg-primary" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix border-black-op-b border-3x">
                                    <div class="float-left mt-10 d-none d-sm-block">
                                        <i class="si si-trophy fa-3x text-primary-light"></i>
                                    </div>
                                    <div class="font-size-h3 font-w600 text-white js-count-to-enabled" data-toggle="countTo" data-speed="1000" data-to="8900"> <strong>{this.state.rewardPoints}</strong></div>
                                    <div class="font-size-sm font-w600 text-uppercase text-white-op">Rewards Points</div>
                                </div>
                            </a>
              </div>
              {/* <div class="col-lg pr-15">
                <a class="block block-link-pop text-center">
                  <div class="block-content">
                    <p class="font-size-h1 ">
                      <strong>{this.state.rewardPoints}</strong>
                    </p>
                    <p class="font-w600">
                      <i class="si si-frame text-muted mr-5"></i>
                      Rewards Points
                    </p>
                  </div>
                </a>
              </div> */}
              <div class="col-lg pr-15" >
              <a class="block block-link-pop text-right bg-earth" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix border-black-op-b border-3x">
                                    <div class="float-left mt-10 d-none d-sm-block">
                                        <i class="si si-cloud-download fa-3x text-earth-light"></i>
                                    </div>
                                    <div class="font-size-h3 font-w600 text-white"><span data-toggle="countTo" data-speed="1000" data-to="2600" class="js-count-to-enabled"> <strong>{this.state.acceptedBookings}</strong></span></div>
                                    <div class="font-size-sm font-w600 text-uppercase text-white-op"> Accepted Bookings</div>
                                </div>
                            </a>
              </div>
              {/* <div class="col-lg pr-15">
                <a class="block block-link-pop text-center">
                  <div class="block-content">
                    <p class="font-size-h1 ">
                      <strong>{this.state.acceptedBookings}</strong>
                    </p>
                    <p class="font-w600">
                      <i class="si si-cloud-download text-muted mr-5"></i>
                      Accepted Bookings
                    </p>
                  </div>
                </a>
              </div> */}
              <div class="col-lg pr-15">
              <a class="block block-link-pop text-right bg-corporate" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix border-black-op-b border-3x">
                                    <div class="float-left mt-10 d-none d-sm-block">
                                        <i class="si si-badge fa-3x text-corporate-light"></i>
                                    </div>
                                    <div class="font-size-h3 font-w600 text-white js-count-to-enabled" data-toggle="countTo" data-speed="1000" data-to="4252"><strong>{this.state.cancelledBookings}</strong></div>
                                    <div class="font-size-sm font-w600 text-uppercase text-white-op">Cancelled Bookings</div>
                                </div>
                            </a>
              </div>
              {/* <div class="col-lg pr-15">
                <a class="block block-link-pop text-center">
                  <div class="block-content">
                    <p class="font-size-h1 ">
                      <strong>{this.state.cancelledBookings}</strong>
                    </p>
                    <p class="font-w600">
                      <i class="si si-badge text-muted mr-5"></i>
                      Cancelled Bookings
                    </p>
                  </div>
                </a>
              </div> */}
              <div  class="col-lg">
              <a class="block block-link-pop text-right bg-elegance" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix border-black-op-b border-3x">
                                    <div class="float-left mt-10 d-none d-sm-block">
                                        <i class="si si-envelope-letter fa-3x text-elegance-light"></i>
                                    </div>
                                    <div class="font-size-h3 font-w600 text-white js-count-to-enabled" data-toggle="countTo" data-speed="1000" data-to="260">
                                      <button
                                          onClick={() => {
                                            this.redirectVendorToBookings(vendorProfile._id);
                                          }}
                                          className=" btn btn-book  fa fa-eye"
                                        ></button>{" "}
                                    </div>
                                    <div class="font-size-sm font-w600 text-uppercase text-white-op">Bookings</div>
                                </div>
                            </a>
              </div>
              {/* <div class="col-lg pr-15">
                <a class="block block-link-pop text-center">
                  <div class="block-content">
                    <p class="font-size-h1 ">
                      <button
                        onClick={() => {
                          this.redirectVendorToBookings(vendorProfile._id);
                        }}
                        className=" btn btn-primary float-right fa fa-eye"
                      ></button>{" "}
                    </p>
                    <p class="font-w600">
                      <i class="si si-badge text-muted mr-5"></i>
                      Bookings
                    </p>
                  </div>
                </a>
              </div> */}
            </div>
            {/* Third Row */}
            <div class="row gutters-tiny pb-15">
               <div  class="col-lg pr-15">
               <a class="block block-link-rotate text-right" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix">
                                    <div class="float-left mt-10 d-none d-sm-block">
                                        <i class="si si-users fa-3x text-primary"></i>
                                    </div>
                                    <div class="font-size-h3 font-w600 text-primary-darker js-count-to-enabled" data-toggle="countTo" data-speed="1000" data-to="1500"><strong>{this.state.completedBookings}</strong></div>
                                    <div class="font-size-sm font-w600 text-uppercase text-primary-dark"> Completed Bookings</div>
                                </div>
                            </a>
               </div>
              {/* <div class="col-lg pr-15">
                <a class="block block-link-pop text-center">
                  <div class="block-content">
                    <p class="font-size-h1 ">
                      <strong>{this.state.completedBookings}</strong>
                    </p>
                    <p class="font-w600">
                      <i class="si si-users text-muted mr-5"></i>
                      Completed Bookings
                    </p>
                  </div>
                </a>
              </div> */}
              <div class="col-lg pr-15">
              <a class="block block-link-rotate text-right" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix">
                                    <div class="float-left mt-10 d-none d-sm-block">
                                        <i class="si si-users fa-3x text-primary"></i>
                                    </div>
                                    <div class="font-size-h6 mb-10 font-w600 text-primary-darker"><span data-toggle="countTo" data-speed="1000" data-to="780" class="js-count-to-enabled"> <strong>{vendorProfile.created_at}</strong></span></div>
                                    <div class="font-size-sm font-w600 text-uppercase text-primary-dark">Vendor Date Joined</div>
                                </div>
                            </a>
              </div>
              {/* <div class="col-lg pr-15">
                <a class="block block-link-pop text-center">
                  <div class="block-content">
                    <p class="font-size-h1 ">
                      <strong>{vendorProfile.created_at}</strong>
                    </p>
                    <p class="font-w600">
                      <i class="si si-users text-muted mr-5"></i>Vendor Date
                      Joined
                    </p>
                  </div>
                </a>
              </div> */}
                    <div class="col-lg">
                    <a class="block block-link-rotate text-right" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix">
                                    <div class="float-left mt-10 d-none d-sm-block">
                                        <i class="si si-envelope-open fa-3x text-primary"></i>
                                    </div>
                                    <div class="font-size-h6 mb-10 font-w600 text-primary-darker js-count-to-enabled" data-toggle="countTo" data-speed="1000" data-to="15"> <strong>
                                        {vendorProfile.lastActive
                                          ? vendorProfile.lastActive
                                          : ""}
                                      </strong>
                                    </div>
                                    <div class="font-size-sm font-w600 text-uppercase text-primary-dark">Last active</div>
                                </div>
                            </a>
                    </div>
              {/* <div class="col-lg pr-15">
                <a class="block block-link-pop text-center">
                  <div class="block-content">
                    <p class="font-size-h1 ">
                      <strong>
                        {vendorProfile.lastActive
                          ? vendorProfile.lastActive
                          : ""}
                      </strong>
                    </p>
                    <p class="font-w600">
                      <i class="si si-users text-muted mr-5"></i>Last active
                    </p>
                  </div>
                </a>
              </div> */}
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default support;
