import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import DateTimePicker from "react-datetime-picker";
import { UserListingActions, VendorListingActions } from "../../actions";
import { Form, Input, Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import appConstants from "../../config/AppConstants";
import DefaultLayout from "../../container/DefaultLayout";
import "react-toastify/dist/ReactToastify.css";

class ScriptList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      message: "",
      users: [],
      dropDownId: [],
      pageNo: 1,
      query: "",
      dropDownUsers: [],
      userlimit: 5,
      allUsers: true,
      isFormValid: false,
      premium: false,
      radioValue: 1,
      premiumPlanSelectedForPushNotification: "",
      inputValue: "",
      notification: "Immediate",
      datePickerValue: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDropDown = this.handleChangeDropDown.bind(this);
    this.initialFunction = this.initialFunction.bind(this);
    this.updateState = this.updateState.bind(this);
  }
  componentDidMount() {
    this.initialFunction();
  }

  //to fetch first 5 users and then so on (pagination)
  initialFunction() {
    VendorListingActions.fetchVendor(
      {
        pageNo: this.state.pageNo,
        limit: this.state.userlimit,
      },
      (err, res) => {
        if (!err) {
          this.setState({ users: res.data });
          let listToSend = [];
          let name;
          this.state.users.map((e) => {
            name = { label: e.phoneNumber, value: e._id };
            this.state.pageNo < 1
              ? (listToSend = [...listToSend, name])
              : (listToSend = [...this.state.dropDownUsers, name]);
            this.setState({ dropDownUsers: listToSend });
          });
        }
      }
    );
  }
  //to fetch users from search value
  searchList() {
    VendorListingActions.fetchVendor(
      {
        searchValue: this.state.inputValue,
        pageNo: this.state.pageNo,
        limit: this.state.userlimit,
      },
      (err, res) => {
        if (!err) {
         this.setState({ users: res.data });
          let listToSend = [];
          let name;
          this.state.users.map((e) => {
            name = { label: e.phoneNumber, value: e._id };
            this.state.pageNo < 1
              ? (listToSend = [...listToSend, name])
              : (listToSend = [...this.state.dropDownUsers, name]);
            this.setState({ dropDownUsers: listToSend });
          });
        }
      }
    );
  }
  //When insert text in title and message
  changeHandler = (event) => {
    console.log(
      "title lenght:",
      this.state.title.length,
      "messgae lenght",
      this.state.message.length
    );

    if (this.state.title.length > 1 && this.state.message.length > 1) {
      this.setState({ isFormValid: true });
    } else {
      this.setState({ isFormValid: false });
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  //When scroll down the dropdown
  incrementPage = () => {
    this.setState({ pageNo: this.state.pageNo + 1 }, () => {
      this.state.inputValue === "" ? this.initialFunction() : this.searchList();
    });
  };
  //when clicked on submit
  submitHandler = async (e) => {
    e.preventDefault();
    this.setState({ isFormValid: false });

    let value = "";
    if (this.state.notification === "Schedule") {
      value = this.state.datePickerValue.toString();
    } else {
      value = "";
    }

if(this.state.radioValue == "1"){
          UserListingActions.allUserNotification(
            {
              title: this.state.title,
              message: this.state.message,
            },
            (err, res) => {
              if (!err) {
                if (res.success === true) {
                  this.setState({
                    title: "",
                    message: "",
                    dropDownId: "",
                  });
                  toast("message sent successfully");
                } else {
                  this.setState({
                    title: "",
                    message: "",
                    dropDownId: "",
                  });
                  toast("token invalid");
                }
              }
            }
          );
}else if( this.state.radioValue == "2" ){
  if (this.state.dropDownId.length < 1) {
    VendorListingActions.allVendorsNotification(
      {
        title: this.state.title,
        message: this.state.message,
      },
      (err, res) => {
        if (!err) {
          if (res.success === true) {
            this.setState({
              title: "",
              message: "",
              dropDownId: "",
            });
            toast("message sent successfully");
          } else {
            this.setState({
              title: "",
              message: "",
              dropDownId: "",
            });
            toast("token invalid");
          }
        }
      }
    );
  } else {
    let id = [];
    this.state.dropDownId.map((e) => {
      id = [...id, e.value];
    });

    VendorListingActions.allVendorsNotification(
      {
        title: this.state.title,
        message: this.state.message,
        id: id,
      },
      (err, res) => {
        if (!err) {
          if (res.success === true) {
            this.setState({
              title: "",
              message: "",
              dropDownId: [],
            });
            toast("message sent successfully");
          } else {
            this.setState({
              title: "",
              message: "",
              dropDownId: [],
            });
            toast("token invalid");
          }
        }
      }
    );
   }
  }
};
  handleChange(event) {
    if (event.target.id === "Immediate") {
      this.setState({ datePickerValue: "" });
    } else if (event.target.id === "Schedule") {
      this.setState({ datePickerValue: new Date() });
    }
    this.setState({
      [event.target.name]: event.target.id,
    });
  }
  handleChangeDropDown(e) {
    this.setState({ dropDownId: e });
  }
  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState(
      { inputValue, dropDownUsers: [], pageNo: 1 },
      this.searchList
    );
    // return inputValue;
  };
  updateState(date) {
    // This function gives you the moment object of date selected.
    console.log(date);
    this.setState({ datePickerValue: date });
  }
  render() {
    let { isFormValid } = this.state;
    let premiumCategory = appConstants.premiumCategory;
    return (
      <div
        id="page-container"
        className="sidebar-o enable-page-overlay side-scroll page-header-modern"
      >
        <DefaultLayout></DefaultLayout>
        <ToastContainer> </ToastContainer>

        <main id="main-container">
          <div className="content " style={{ paddingTop: 0 }}>
            <div className="row mb-30 mt-30">
              <div className="col-6">
                <h2 className="pt-0 mt-10 mb-0 ">Send Push Notification</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="block">
                  <div className="block-content block-content-full pb-50">
                    <Form>
                      <FormGroup row>
                        <Label for="exampleEmail" sm={2}>
                          Notification Title
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="text"
                            value={this.state.title}
                            name="title"
                            onChange={this.changeHandler}
                            id="exampleEmail"
                            placeholder="Title..."
                            maxlength="10"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row style={{ marginTop: 25 }}>
                        <Label for="exampleText" sm={2}>
                          Notification Text
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="textarea"
                            name="message"
                            value={this.state.message}
                            onChange={this.changeHandler}
                            id="exampleText"
                            rows={10}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row style={{ marginTop: 25 }}>
                        <Label for="checkbox2" sm={2}>
                          To
                        </Label>
                        <Col>
                          <Label sm={2}>
                            <Input
                              type="radio"
                              id="1"
                              defaultChecked
                              onChange={this.handleChange}
                              name="radioValue"
                            />
                            Users
                          </Label>
                          <Label sm={2}>
                            <Input
                              type="radio"
                              id="2"
                              // checked={premium}
                              onChange={this.handleChange}
                              name="radioValue"
                            />
                           Vendors
                          </Label>
                          {/* <Label sm={2}>
                            <Input
                              type="radio"
                              id="3"
                              // checked={premium}
                              onChange={this.handleChange}
                              name="radioValue"
                            />
                            Specific User
                          </Label> */}
                          {/* <Label sm={2}>
                            <Input
                              type="radio"
                              id="4"
                              // checked={premium}
                              onChange={this.handleChange}
                              name="radioValue"
                            />
                            Free Users
                          </Label> */}
                        </Col>
                      </FormGroup>

                      {this.state.radioValue == 1 ? (
                         <FormGroup row style={{ marginTop: 25 }}>
                         <Label for="checkbox2" sm={2}>
                           Select User
                         </Label>
                         <Col>
                           <Label sm={2}>
                             <Input
                               type="radio"
                               id="1"
                               defaultChecked
                               onChange={this.handleChange}
                               name="radioValueUser"
                             />
                            All Users
                           </Label>
                           <Label sm={2}>
                             <Input
                               type="radio"
                               id="2"
                               // checked={premium}
                               disabled
                               onChange={this.handleChange}
                               name="radioValueUser"
                             />
                           Subscribed Users
                           </Label>
                           <Label sm={2}>
                             <Input
                               type="radio"
                               id="3"
                               // checked={premium}
                               onChange={this.handleChange}
                               name="radioValueUser"
                             />
                             Free Users
                           </Label>
                         </Col>
                       </FormGroup>
                      ) : this.state.radioValue == 2 ? (
                        <FormGroup row style={{ marginTop: 25 }}>
                        <Label for="checkbox2" sm={2}>
                          Select Vendor
                        </Label>
                        <Col>
                          <Label sm={2}>
                            <Input
                              type="radio"
                              id="1"
                              // defaultChecked
                              onChange={this.handleChange}
                              name="radioValueVendor"
                            />
                            All Vendors
                          </Label>
                          <Label sm={2}>
                            <Input
                              type="radio"
                              id="2"
                              // checked={premium}
                              onChange={this.handleChange}
                              name="radioValueVendor"
                            />
                          Specific Vendors
                          </Label>
                        </Col>
                      </FormGroup>
                      ) : (
                        ""
                      )}
                      {this.state.radioValueVendor == "2" ? 
                      <FormGroup row>
                        <Label for="exampleEmail" sm={2}>
                          Search Vendor
                        </Label>
                        <Col sm={10}>
                        <Select
                            className="col col-lg-3"
                            options={this.state.dropDownUsers}
                            maxMenuHeight={120}
                            isMulti
                            placeholder="Username"
                            onChange={this.handleChangeDropDown}
                            name="dropDownId"
                            onInputChange={this.handleInputChange}
                            onMenuScrollToBottom={() => this.incrementPage()}
                          />
                        </Col>
                      </FormGroup>:"" }
                      {/* <FormGroup row>
                        <Label for="exampleEmail" sm={2}>
                          Schedule Notification
                        </Label>
                        <Col sm={10}>
                          <Label sm={2}>
                            <Input
                              type="radio"
                              id="Immediate"
                              onChange={this.handleChange}
                              name="notification"
                              defaultChecked
                            />
                            Immediate
                          </Label>
                          <Label sm={2}>
                            <Input
                              type="radio"
                              id="Schedule"
                              onChange={this.handleChange}
                              name="notification"
                            />
                            Schedule
                          </Label>
                          {this.state.notification == "Schedule" ? (
                            <DateTimePicker
                              value={this.state.datePickerValue}
                              onChange={this.updateState}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </FormGroup> */}
                      <FormGroup row style={{ marginTop: 25 }}>
                        <Label sm={2}></Label>
                        <Col sm={10}>
                          <button
                            disabled={!isFormValid}
                            class="btn btn-primary "
                            onClick={this.submitHandler}
                          >
                            Send
                          </button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default ScriptList;
