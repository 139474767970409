import React, { Component } from "react";
import Navbar from "./Navbar";
import Header from "./Header";
class DefaultLayout extends Component {
  render() {
    return (
      <div
        id="page-container"
        className="sidebar-o enable-page-overlay side-scroll page-header-modern main-content-boxed"
      >
        <Header></Header>
        <Navbar></Navbar>
      </div>
    );
  }
}

export default DefaultLayout;
