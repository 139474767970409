import Agent from "../services/RequestInstance";
import { ServerError } from "../utils/helpers";

import config from "../config/Config";

const BACKEND_URL = config.BACKEND_URL;

function fetchSupport(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/allSupport`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function updateStatus(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/updateStatus`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

export default {
    fetchSupport,
    updateStatus
};
