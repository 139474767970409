import Agent from "../services/RequestInstance";
import { ServerError } from "../utils/helpers";

import config from "../config/Config";

const BACKEND_URL = config.BACKEND_URL;

function fetchBanner(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/fetchBanner`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function feature(payload, cb) {
    Agent.fire("post", `${BACKEND_URL}/admin/featureBanner`)
      .query(payload)
      .end((err, res) => {
        var error =
          err || res.error
            ? ServerError(res)
            : res.body && res.body.error
            ? ServerError(res)
            : null;
        if (typeof cb === "function") return cb(error, res && res.body);
      });
  }
  function deleteBanner(payload, cb) {
    Agent.fire("get", `${BACKEND_URL}/admin/deleteBanner`)
      .query(payload)
      .end((err, res) => {
        var error =
          err || res.error
            ? ServerError(res)
            : res.body && res.body.error
            ? ServerError(res)
            : null;
        if (typeof cb === "function") return cb(error, res && res.body);
      });
  }
 
export default {
    fetchBanner,
    feature,
    deleteBanner,
};
