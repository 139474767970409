const loginType = {
  USER: "1",
  ADMIN: "2",
};

const SERVER_ERROR = "Something went on server.";
// Used to show date and time ui
const LOCAL_DATE_FORMAT = "MMMM DD, YYYY";
const LOCAL_DATE_TIME_FORMAT = "MMMM DD, YYYY hh:mm A";
const LOCAL_DATE_S_FORMAT = "MMM DD, YYYY HH:mm A";
const LOCAL_DATE_TIME_SHORT_FORMAT = "MMM DD, YYYY hh:mm A";
const LOCAL_DATE_SHORT_FORMAT = "MMM DD, YYYY";
// Used while sending data on sever
const DATE_FORMAT = "YYYY-MM-DD";
const TIME_FORMAT = "HH:MM";

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

//Used in date picker
const DATE_PICKER_DATE_AND_TIME_FORMAT = "MMMM dd, yyyy hh:mm: a";
const DATE_PICKER_DATE_FORMAT = "MMMM dd, yyyy"; /*"")*/
const DATE_PICKER_TIME_FORMAT = "p";
const LOCAL_DATE_SHORTS_FORMAT = "MMM dd, yyyy";

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8];
const minutes = [0, 15, 30, 45];
const APP_VERSION = "1";
const NO_OF_INSTALLMENTS = 7;
const TERMS_AND_CONDITONS_LINK =
  "https://www.analytixlabs.co.in/terms-conditions";
const ALABS_WEBSITE = "https://www.analytixlabs.co.in/";
const days = [
  {
    value: 7,
    label: "Sunday",
  },
  {
    value: 1,
    label: "Monday",
  },
  {
    value: 2,
    label: "Tuesday",
  },
  {
    value: 3,
    label: "Wednesday",
  },
  {
    value: 4,
    label: "Thursday",
  },
  {
    value: 5,
    label: "Friday",
  },
  {
    value: 6,
    label: "Saturday",
  },
];
const modalData = {
  modalStyle: {
    overlay: {
      zIndex: 9999999999,
      overflowX: "hidden",
      overflowY: "auto",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
    content: {
      margin: "0% auto",
      width: "unset",
      border: "none",
      background: "none",
    },
  },
  confirmationPopUpStyple: {
    overlay: {
      zIndex: 9999999999,
      overflowX: "hidden",
      overflowY: "auto",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
    content: {
      width: "40%",
    },
  },
  pageLength: 10,
};

const marketTypes = [
  {
    value: "Daily",
    label: "Daily",
  },
  {
    value: "Auction",
    label: "Auction",
  },
  {
    value: "Options",
    label: "Options",
  },
];
const types = [
  {
    value: "Normal",
    label: "Normal",
  },
  {
    value: "CIF",
    label: "CIF",
  },
  {
    value: "ALBM",
    label: "ALBM",
  },
];
const accountTypes = {
  Demat: "Demat",
  Current: "Current",
};

const currencyList =[
    {
        value:"USD",
        label:"USD",
    }, {
        value:"INR",
        label:"INR",
    },{
        value:"AED",
        label:"AED",
    },
    {
        value:"SGD",
        label:"SGD",
    }, {
        value:"RMB",
        label:"RMB",
    },{
        value:"EUR",
        label:"EUR",
    }
] ;
const transactionTypes = {
    Credit: "Credit",
    Debit: "Debit",
};
const SIZE_PER_PAGE = 10;
const NO_DATA_TEXT = "No entries available.";

const roles = {
  admin: {
    name: "admin",
    displayName: "Admin",
  },
  counsellor: {
    name: "counsellor",
    displayName: "Counsellor",
  },
  evaluator: {
    name: "evaluator",
    displayName: "Evaluator",
  },
  trainer: {
    name: "trainer",
    displayName: "Trainer",
  },
  superAdmin: {
    name: "superAdmin",
    displayName: "Super Admin",
  },
};

const data = {
  modalStyle: {
    overlay: {
      zIndex: 9999999999,
      overflowX: "hidden",
      overflowY: "auto",
      backgroundColor: "rgba(0, 34, 72, 1)",
    },
    content: {
      margin: "0% auto",
      width: "unset",
      border: "none",
      background: "none",
    },
  },
  confirmationPopUpStyple: {
    overlay: {
      zIndex: 9999999999,
      overflowX: "hidden",
      overflowY: "auto",
      backgroundColor: "rgba(0, 34, 72, 1)",
    },
    content: {
      width: "40%",
    },
  },
  pageLength: 10,
};
const cancelStatus = {
  credit: "credit",
  void: "void",
  refund: "refund",
};

const orderCanceltatus = {
  void: "VOID",
  credit: "CREDIT",
  refund: "REFUND",
};

const installmentStatus = {
  paid: "PAID",
  due: "DUE",
  processing: "PROCESSING",
};

const triggers = [
  {
    value: "EVENT_NOTIFICATION",
    label: "EVENT_NOTIFICATION",
  },
  {
    value: "EVENT_UPDATE_NOTIFICATION",
    label: "EVENT_UPDATE_NOTIFICATION",
  },
  {
    value: "SELF_PACED_BATCH_NOTIFICATION",
    label: "SELF_PACED_BATCH_NOTIFICATION",
  },
  {
    value: "LIVE_BATCH_NOTIFICATION",
    label: "LIVE_BATCH_NOTIFICATION",
  },
  {
    value: "PAYMENT_FAILURE",
    label: "PAYMENT_FAILURE",
  },
  {
    value: "ADMIN_PAY_INSTALLMENT",
    label: "ADMIN_PAY_INSTALLMENT",
  },
  {
    value: "ADMIN_PAY_FULL",
    label: "ADMIN_PAY_FULL",
  },
  {
    value: "STUDENT_PAY_INSTALLMENT",
    label: "STUDENT_PAY_INSTALLMENT",
  },
  {
    value: "STUDENT_PAY_FULL",
    label: "STUDENT_PAY_FULL",
  },
  {
    value: "BEFORE_DUE_DATE",
    label: "BEFORE_DUE_DATE",
  },
  {
    value: "ON_DUE_DATE",
    label: "ON_DUE_DATE",
  },
  {
    value: "AFTER_DUE_DATE",
    label: "AFTER_DUE_DATE",
  },
  {
    value: "PRODUCT_COURSE_SIGNUP",
    label: "PRODUCT_COURSE_SIGNUP",
  },
  {
    value: "SIGNUP_NOTIFICATION",
    label: "SIGNUP_NOTIFICATION",
  },
  {
    value: "CASE_STUDY_EVALUATED",
    label: "CASE_STUDY_EVALUATED",
  },
  {
    value: "CREATE_ORDER_MAIL",
    label: "CREATE_ORDER_MAIL",
  },
  {
    value: "FORGOT_PASSWORD",
    label: "FORGOT_PASSWORD",
  },
  {
    value: "BATCH_REMINDER",
    label: "BATCH_REMINDER",
  },
  {
    value: "VERIFY_EMAIL",
    label: "VERIFY_EMAIL",
  },
  {
    value: "CANCEL_ORDER",
    label: "CANCEL_ORDER",
  },
  {
    value: "INSTALLMENT_CHANGE",
    label: "INSTALLMENT_CHANGE",
  },
  {
    value: "LOGIN_CREDENTIALS",
    label: "LOGIN_CREDENTIALS",
  },
  {
    value: "AFTER_DUE_DATE_WITH_LATE_FEE",
    label: "AFTER_DUE_DATE_WITH_LATE_FEE",
  },
];

const testimonyImageBaseUrl = "https://devapi.analytixlabs.co.in/public";
const BLOG_URL = "https://www.analytixlabs.co.in/blog/";
const namePrefixes = [
  {
    value: "Mr.",
    label: "Mr.",
  },
  {
    value: "Mrs.",
    label: "Mrs.",
  },
  {
    value: "Miss",
    label: "Miss",
  },
  {
    value: "Others",
    label: "Others",
  },
];

const squareUps = [
  {
    value: "One Side",
    label: "One Side",
  },
  {
    value: "Both Side",
    label: "Both Side",
  },
  {
    value: "Opposite Side",
    label: "Opposite Side",
  },
];

const roundOffs = [
  {
    value: "Lower Paisa",
    label: "Lower Paisae",
  },
  {
    value: "Nearest Paisa",
    label: "Nearest Paisa",
  },
  {
    value: "Higher Paisa",
    label: "Higher Paisa",
  },
  {
    value: "Lower 5 Paise",
    label: "Lower 5 Paise",
  },
  {
    value: "Nearest 5 Paise",
    label: "Nearest 5 Paise",
  },
  {
    value: "Higher 5 Paise",
    label: "Higher 5 Paise",
  },
  {
    value: "Four Digits",
    label: "Four Digits",
  },
  {
    value: "4 Dec Rate 2 Dec Bkg",
    label: "4 Dec Rate 2 Dec Bkg",
  },
  {
    value: "No Round Off",
    label: "No Round Off",
  },
  {
    value: "Nearest Rupee",
    label: "Nearest Rupee",
  },
];

const riskCategories = [
  {
    value: "Low",
    label: "Low",
  },
  {
    value: "Medium",
    label: "Medium",
  },
  {
    value: "High",
    label: "High",
  },
];
const premiumCategory = [
  {
    value: "Essential",
    label: "Essential",
  },
  {
    value: "Professional",
    label: "Professional",
  },
  {
    value: "Enterprise",
    label: "Enterprise",
  },
];
const planCategory = [
  {
    value: "free",
    label: "free",
  },
  {
    value: "premium",
    label: "premium",
  },
];
const bookingStatus = [
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "outForService",
    label: "Out For Service",
  },
  {
    value: "jobStarted",
    label: "Job Started",
  }, {
    value: "accepted",
    label: "Accepted",
  }, {
    value: "confirmed",
    label: "Confirmed",
  }, {
    value: "reachedDestination",
    label: "Reached Destination",
  }, {
    value: "completed",
    label: "Completed",
  },
  {
    value: "completedByVendor",
    label: "Completed By Vendor",
  }, {
    value: "unableToComplete",
    label: "Unable To Complete",
  },
  {
    value: "reScheduled",
    label: "Re-Scheduled",
  }
];
const supportStatus = [
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "resolved",
    label: "Resolved",
  },
];
export default {
  supportStatus,
  modalData,
  premiumCategory,
  planCategory,
  accountTypes,
  squareUps,
  roundOffs,
  riskCategories,
  SERVER_ERROR,
  loginType,
  DATE_FORMAT,
  LOCAL_DATE_FORMAT,
  LOCAL_DATE_SHORT_FORMAT,
  LOCAL_DATE_TIME_SHORT_FORMAT,
  DATE_TIME_FORMAT,
  DATE_PICKER_DATE_FORMAT,
  DATE_PICKER_TIME_FORMAT,
  DATE_PICKER_DATE_AND_TIME_FORMAT,
  weekDays,
  days,
  hours,
  minutes,
  APP_VERSION,
  SIZE_PER_PAGE,
  NO_DATA_TEXT,
  LOCAL_DATE_TIME_FORMAT,
  ROLES: roles,
  DATA: data,
  TIME_FORMAT,
  NO_OF_INSTALLMENTS,
  cancelStatus,
  orderCanceltatus,
  installmentStatus,
  triggers,
  testimonyImageBaseUrl,
  TERMS_AND_CONDITONS_LINK,
  ALABS_WEBSITE,
  BLOG_URL,
  LOCAL_DATE_SHORTS_FORMAT,
  LOCAL_DATE_S_FORMAT,
  marketTypes,
  types,
  namePrefixes,
  bookingStatus
};
