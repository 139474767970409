import Agent from "../services/RequestInstance";
import { ServerError } from "../utils/helpers";

import config from "../config/Config";

const BACKEND_URL = config.BACKEND_URL;

function fetchCategory(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/listCategory`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function fetchQuotes(payload, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/listQuote`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function addQuote(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/addQuote`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function deleteQuote(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/deleteQuote`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function updateQuote(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/updateQuote`)
    .query(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
export default {
  fetchCategory,
  addQuote,
  fetchQuotes,
  deleteQuote,
  updateQuote,
};
