import React, { Component } from "react";
import {
  CategoryListingActions,
  VideosActions,
  PromoActions,
  SupportActions
} from "../../actions";
import ReactPaginate from "react-paginate";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import DefaultLayout from "../../container/DefaultLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "../AddSubCategories/node_modules/toasted-notes/src/styles.css";
import appConstants from "../../config/AppConstants";
import superagent from "superagent";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import imageCompression from "browser-image-compression";
import config from "../../config/Config";
import Select from "react-select";

const supportStatus = appConstants.supportStatus;

const BACKEND_URL = config.BACKEND_URL;

class support extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      allCategories: [],
      allSubCategories: [],
      allServices: [],
      loading: true,
      modal: false,
      update: false,
      categoryName: "",
      categoryId: "",
      query: "",
      img: "",
      subCategoryName: "",
      serviceName: "",
      description: "",
      price: "",
      multiple: false,
      category: "",
      startDate: new Date(),
      expiryDateANdTIme: "",
      couponExpiryDateAndTime: "",
      radioValue: "",
      dropDownUsers:[],
      dropDownId:[],
      comment:"",
      supportStatus:""
    };
    this.fetchData = this.fetchData.bind(this);
    // this.onUploadFile = this.onUploadFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let { query } = this.state;
    SupportActions.fetchSupport({}, (err, res) => {
      if (!err) {
        this.setState({
          allServices: res.data,
          loading: false,
        });
      }
    });
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  changeHandler = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.fetchData();
      }
    );
  };

  handleChange(event) {
    if(event.target.name == "radioValueCategorySpecified" && event.target.value == "all"){
      this.setState({dropDownId:[]})
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  addCategory = () => {
    {
      SupportActions.updateStatus(
          {
            comment: this.state.comment,
            supportStatus: this.state.supportStatus,
            id:this.state.supportId
          },
          (err, res) => {
            if (!err) {
              console.log(res);
              this.setState({
                modal: !this.state.modal,   
                supportId:"",
                supportStatus:"",
                comment:""            
              });
              toast.success(res.message);
              this.fetchData();
            }else{
              toast.error(res.message);

            }
          }
        )
    }
  };

  setDate = (date) => {
    this.setState({
      couponExpiryDateAndTime: date,
    });
  };
  handleChangeDropDown = (e) => {
    this.setState({ dropDownId: e });
  }
  render() {
    const {
      allServices,
      allSubCategories,
      loading,
      query,
      allCategories,
      expiryDateANdTIme,
      startDate,
      couponExpiryDateAndTime,
    } = this.state;
    return (
      <div
        id="page-container"
        className="sidebar-o enable-page-overlay side-scroll page-header-modern"
      >
        <DefaultLayout></DefaultLayout>
        <ToastContainer> </ToastContainer>

        <main id="main-container">
          <div className="content" style={{ paddingTop: 0 }}>
            <div className="row mb-30 mt-30">
              <div className="col-3">
                <h2>Vendor Support</h2>
              </div>

              <React.Fragment>
                {" "}
                <div class="col-9">
                  <ul
                    className="breadbrumb-ul-input"
                    style={{
                      listStyle: "none",
                      paddingLeft: "0px",
                      marginBottom: "0px",
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    <li class="w-300px pl-4"> </li>
                    <li style={{ display: "inline-Block", marginLeft: "10px" }}>
                     
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            </div>
            {loading ? (
              this.loading()
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="block">
                    <div className="block-content block-content-full pt-30">
                      <div class="table-responsive">
                        <table className="table table-striped table-borderless table-vcenter dataTable no-footer">
                          <thead
                            style={{
                              verticalAlign: "baseline",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <tr>
                              <th className="text-black">
                                <b>Title</b>
                              </th>
                              <th className="text-black">
                                <b>Message</b>
                              </th>
                              <th className="text-black">
                                <b>Vendor</b>
                              </th>
                              <th className="text-black">
                                <b>Status</b>
                              </th>
                              <th className="text-black">
                                <b>Comment</b>
                              </th>
                              <th className="text-black">
                                <b>Action</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {allServices.map((category) => {
                              return (
                                <tr>
                                  <td className="font-w600">
                                    {category && category.title
                                      ? category.title
                                      : ""}
                                  </td>
                                  <td className="font-w600">{category && category.message
                                      ? category.message
                                      : ""}</td>
                                  <td className="font-w600">
                                    {category && category.vendorId
                                      ? category.vendorId.phoneNumber
                                      : ""}
                                  </td>
                                  <td className="font-w600">
                                    {category && category.status
                                      ? category.status
                                      : ""}
                                  </td>
                                  <td className="font-w600">
                                    {category && category.comment
                                      ? category.comment
                                      : "N/A"}
                                  </td>
                                  <td className="">
                                    <div class="btn-group">
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-secondary js-tooltip-enabled"
                                        data-toggle="tooltip"
                                        title="Edit"
                                        onClick={() => {
                                          this.setState({
                                            modal: !this.state.modal,
                                            supportId: category._id,
                                          });
                                        }}
                                        data-original-title="Edit"
                                      >
                                        <i class="fa fa-pencil"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>{" "}
                      <br />
                      <Modal isOpen={this.state.modal} className="p-0">
                        {this.state.update === true ? (
                          <ModalHeader className="pb-2">Edit Promo</ModalHeader>
                        ) : (
                          <ModalHeader className="pb-2">Update Support</ModalHeader>
                        )}

                        {this.state.loadingModal ? (
                          <div className="animated fadeIn pt-1 text-center">
                            Loading...
                          </div>
                        ) : (
                          <ModalBody>
                            {this.state.modalDisplayValue === "remove" ? (
                              ""
                            ) : (
                              <div>
                                <div className="row">
                                  <div className="col-md-4">
                                    <label>Status:</label>
                                  </div>
                                  <div className="col-md-8">
                                  <select
                                  name="supportStatus"
                                  id="supportStatus"
                                  value={this.state.supportStatus}
                                  onChange={this.changeHandler}
                                  class="form-control custom-select"
                                >
                                  <option value="" disabled>Status</option>
                                  {supportStatus.map((e) => {
                                    return (
                                      <option value={e.value} key={e.value}>
                                        {e.label}
                                      </option>
                                    );
                                  })}
                                </select>
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="col-md-4">
                                    <label>Comment:</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      name="comment"
                                      class="form-control"
                                      value={this.state.comment}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </ModalBody>
                        )}
                        <ModalFooter>
                          <Button
                            color="primary"
                            // disabled={
                            //   this.state.modalDisplayValue === "remove"
                            //     ? false
                            //     : !this.state.isFormValid
                            // }
                            onClick={this.addCategory}
                          >
                            Proceed
                          </Button>
                          <Button
                            color="secondary"
                            onClick={() =>
                              this.setState({
                                modal: false,
                                categoryName: "",
                                update: false,
                              })
                            }
                          >
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    );
  }
}

export default support;
