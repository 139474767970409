import React, { Component } from "react";
import { UserListingActions, BookingActions } from "../../actions";
import ReactPaginate from "react-paginate";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import DefaultLayout from "../../container/DefaultLayout";
import BreadCrumb from "../BreadCrumb";

import appConstants from "../../config/AppConstants";
import moment from "moment";

const bookingStatus = appConstants.bookingStatus;
var qs = require("qs");

class ExchangeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      query: "",
      pageNo: 1,
      userCount: 0,
      bookinglimit: 10,
      pageCount: 0,
      loading: true,
      userNameForToggle: "",
      toggleValue: false,
      loadingModal: true,
      userIdForToggle: "",
      planToBeGivenByAdmin: "",
      premiumPlanSelectedForUserListing: "",
      noOfDaysForPremiumMembershipGrant: 1,
      planCategory: "",
      modalDisplayValue: "",
      modal: false,
      isFormValid: false,
      defaultImage: `/assets/img/user-not-found.png`,
      services: [],
      bookingStatus:"",
      bookings:[]
    };
    this.fetchNextList = this.fetchNextList.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let {
      pageNo,
      bookinglimit,
      bookingStatus,
    } = this.state;
    let vendorId;
    vendorId = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).vendorId;
    let userId;
    userId = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).userId;
    BookingActions.fetchBookings(
      {
        pageNo,
        limit: bookinglimit,
        bookingStatus:bookingStatus,
        vendorId,
        userId      
      },
      (err, res) => {
        if (!err) {
          this.setState({
            pageCount: Math.ceil(res.count / this.state.bookinglimit),
            bookings: res.data,
            loading: false,
          });
        }
      }
    );
  }
  fetchNextList(pageNumber) {
    let page = pageNumber.selected + 1;
    this.setState(
      {
        pageNo: page,
        loading:true
      },
      () => {
        this.fetchData();
      }
    );
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );
  viewCard(id) {
    this.props.history.push(`/viewCard/${id}`);
  }
  downloadCsv() {
    UserListingActions.addExchange({}, (res) => {});
  }
  changeHandler = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        pageNo: 1,
      },
      () => {
        this.fetchData();
      }
    );
  };
  makePremium = () => {
    BookingActions.makePremium(
      {
        id: this.state.userIdForToggle,
        premiumCategory: this.state.planToBeGivenByAdmin,
        planExpiryDate: moment()
          .add(this.state.noOfDaysForPremiumMembershipGrant, "days")
          .toString(),
      },
      (err, res) => {
        if (!err) {
          console.log(res);
          this.setState({
            modal: !this.state.modal,
            loading: false,
            isFormValid: false,
            noOfDaysForPremiumMembershipGrant: 1,
          });
          this.fetchData();
        }
      }
    );
  };
  handleChange(event) {
    let target = event.target,
      value = target.type === "checkbox" ? target.checked : target.value,
      name = target.name;
    if (event.target.value !== "Premium") {
      this.setState({ premiumPlanSelectedForUserListing: "" });
    }
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        if (this.state.planToBeGivenByAdmin === "") {
          this.fetchData();
          this.setState({ isFormValid: false });
        } else {
          this.setState({ isFormValid: true });
        }
      }
    );
  }

  toggle = (name, id, value) => {
    this.setState({
      services: [],
      //   userIdForToggle: id,
      //   modalDisplayValue: value,
      modal: !this.state.modal,
      //   userNameForToggle: name,
      //   loadingModal: false,
    });
  };

  redirectVendor = (id) =>{
    this.props.history.push(`/viewVendorProfile/${id}`);
  }

  redirectViewBooking = (id) =>{
    this.props.history.push(`/Bookings/ViewBooking?id=${id}`);
  }
  
  render() {
    const { bookings, loading, pageCount, defaultImage } = this.state;
    let premiumCategory = appConstants.premiumCategory;
    let planCategory = appConstants.planCategory;
    console.log(this.state.noOfDaysForPremiumMembershipGrant);
    return (
      <div
        id="page-container"
        className="sidebar-o enable-page-overlay side-scroll page-header-modern"
      >
        <DefaultLayout></DefaultLayout>
        <main id="main-container">
          <div className="content " style={{ paddingTop: 0 }}>
            
            <div className="row">
             <div class="col-3" >
             <BreadCrumb {...this.props}></BreadCrumb>
             </div>
            
              
              <React.Fragment>
                {" "}
                <div class="col-9">
                  <ul
                    className="breadbrumb-ul-input"
                    style={{
                      listStyle: "none",
                      paddingLeft: "0px",
                      marginBottom: "0px",
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    {/* <li
                        style={{ display: "inline-Block", marginLeft: "10px" }}
                      >
                        <div className="">
                          <select
                            name="planCategory"
                            id="planCategory"
                            value={this.state.planCategory}
                            onChange={this.handleChange}
                            class="form-control custom-select"
                          >
                            <option value="">All Category</option>
                            {planCategory.map((e) => {
                              return (
                                <option value={e.value} key={e.value}>
                                  {e.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </li> */}
                    <li style={{ display: "inline-Block", marginLeft: "10px" }}>
                      <div class="">
                        <div class="d-flex justify-content-end">
                          <div class="text-right mr-15">
                            <ul class="filter-ul ">
                              <li class="w-300px pl-4">
                                <form>
                                <div class="text-right mr-15">
                            <ul class="filter-ul ">
                              <li class="w-300px pl-4">
                              <select
                                  name="bookingStatus"
                                  id="bookingStatus"
                                  value={this.state.bookingStatus}
                                  onChange={this.changeHandler}
                                  class="form-control custom-select"
                                >
                                  <option value="">All Category</option>
                                  {bookingStatus.map((e) => {
                                    return (
                                      <option value={e.value} key={e.value}>
                                        {e.label}
                                      </option>
                                    );
                                  })}
                                </select>
                              </li>
                            </ul>
                          </div>
                                  {/* <div class="input-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Search"
                                      id="text"
                                      name="query"
                                      value={this.state.query}
                                      onChange={this.changeHandler}
                                    />
                                    <div class="input-group-append">
                                      <button
                                        type="submit"
                                        disabled
                                        class="btn btn-secondary"
                                      >
                                        <i class="fa fa-search"></i>
                                      </button>
                                    </div>
                                  </div> */}
                                </form>
                              </li>
                            </ul>
                          </div>
                          {/* <div>
                              <button
                                class="btn btn-md btn-primary float-right text-nowrap"
                                onClick={this.downloadCsv}
                              >
                                Download CSV
                              </button>
                            </div> */}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </React.Fragment>

              {/* start */}

              {/* end */}
             
            </div>
            {loading ? (
              this.loading()
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="block">
                    {/* <div className="block-header block-header-default">
                    <div className="row w-100">
                      <div className="col-lg-12">
                        <div className="text-right"></div>
                      </div>
                    </div>
                  </div> */}
                    <div className="block-content block-content-full pt-30 p-30">
                      <div class="custom-table t2">
                      <div class="table-responsive">
                        <table className="table table-striped table-borderless table-vcenter dataTable no-footer">
                          <thead
                            style={{
                              verticalAlign: "baseline",
                              whiteSpace: "nowrap",
                            }}
                            className="text-center"
                          >
                            <tr className="text-center">
                            <th className="text-black">
                                <b>Service</b>
                              </th>
                              <th className="text-black">
                                <b>User Contact</b>
                              </th>
                              <th className="text-black">
                                <b>Area</b>
                              </th>
                              <th className="text-black">
                                <b>Scheduled Date</b>
                              </th>
                              <th className="text-black">
                                <b>Arrival Requested</b>
                              </th>
                            
                              {/* <th className="text-black">
                                <b>Date Joined</b>
                              </th> */}
                              <th className="text-black">
                                <b>status</b>
                              </th>
                              <th className="text-black text-right">
                                <b>Review</b>
                              </th>
                              {/* <th className="text-black">
                                <b>Services</b>
                              </th> */}
                              <th className="text-black">
                                <b>Invoice</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {bookings.map((user) => {
                              return (
                                <tr className="text-center">
                                    <td className="font-w600">
                                    {user.subCategoryId
                                      ? user.subCategoryId.subCategoryName
                                      : null}
                                  </td>
                                  <td className="font-w600">
                                    {user.userId ?`${user.userId.countryCode}-${user.userId.phoneNumber}`  : null}
                                  </td>
                                  <td className="font-w600">
                                    {user.state
                                      ? `${user.state}`
                                      : "N/A "}
                                  </td>
                                  <td className="font-w600">
                                    {user && user.scheduledDate
                                      ? moment(user.scheduledDate).format(
                                          "DD,MMMM,YYYY"
                                        )
                                      : ""}{" "}
                                  </td>
                                  <td className="font-w600">
                                    {user
                                      ? `${user.scheduleTime}`
                                      : null}
                                  </td>
                                  <td className="font-w600">
                                    {user ? user.status : null}
                                  </td>
                                  <td className="font-w600">
                                    {user.reviewed ? user.review : "N/A"}
                                  </td>
                                  {/* <td className="font-w600">
                                    {user.vendorId ?<> {user.vendorId.countryCode}-{user.vendorId.phoneNumber} <button
                                      onClick={() => {
                                     this.redirectVendor(user.vendorId._id)
                                      }}
                                      className=" btn btn-primary float-right fa fa-eye"
                                    ></button> </>: "Not Assigned"}
                                  </td> */}
                                  {/* <td className="font-w600">
                                     <button
                                     onClick={() => {
                                      this.redirectVendor(user.vendorId._id)
                                       }}
                                      className=" btn btn-primary float-right fa fa-eye"
                                    ></button>
                                  </td> */}
                                  {/*  <td className="d-none d-sm-table-cell">
                                      <div
                                        style={{
                                          display: "flex",
                                        }}
                                      >
                                        <Switch
                                          checked={user.pushNotification}
                                          onColor="#3f9ce8"
                                          checkedIcon={false}
                                          height={20}
                                          width={40}
                                          disabled={true}
                                          // onChange={() => {
                                          //   let remove = "remove";
                                          //   this.toggle(
                                          //     user.firstName,
                                          //     user._id,
                                          //     remove
                                          //   );
                                          // }}
                                        />
                                      </div>
                                      </td>*/}
                                  {/* <td className="font-w600">
                                    <button
                                      onClick={() => {
                                        this.setState({
                                          modal: !this.state.modal,
                                          services: user.service,
                                          subCategoryName : user.subCategoryId.subCategoryName
                                        });
                                      }}
                                      className=" btn btn-primary float-right fa fa-eye"
                                    ></button>
                                  </td> */}
                                  <td className="font-w600">
                                  <button
                                      onClick={() => {
                                     this.redirectViewBooking(user._id)
                                      }}
                                      className=" btn btn-primary fa fa-eye"
                                    ></button> 
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

            

                      </div>
                     </div>
                      <br />
                      <br />
                      <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={this.state.userCount}
                        pageRangeDisplayed={this.state.userlimit}
                        onPageChange={this.fetchNextList}
                        containerClassName={"pagination-student"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.pageNo - 1}
                      />
                      <Modal isOpen={this.state.modal} className="p-0">
                        <ModalHeader className="pb-2">
                          {this.state.subCategoryName}
                        </ModalHeader>
                        <ModalBody>
                          <div>
                            {this.state.services.map((ser) => {
                            return  (<div className="row" style={{ paddingTop: 23 }}>
                                <div className="col-md-4">
                                  <label>{ser.serviceid.serviceName} * {ser.quantity}</label>
                                </div>
                                <div className="col-md-4">
                                  <label>${ser.serviceid.price} </label>
                                </div>
                                
                              </div>)
                            })}
                          </div>
                        </ModalBody>
                        {/* )} */}
                        <ModalFooter>
                          <Button
                            color="secondary"
                            onClick={() => this.toggle(0)}
                          >
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    );
  }
}

export default ExchangeList;
